import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "App";
import { useLocation, useNavigate } from "react-router-dom";
import { styleHelpers } from "helpers/styleHelpers";
import EditProfileMenu from "components/Header/EditProfileMenu";
import LOGO from "assets/images/login/logo.png";
import LocDeConsumContainer from "components/Header/LocDeConsumContainer";
import WelcomeContainer from "components/Header/WelcomeContainer";
import NotificationCards from "components/NotificationCards";
import ConturiAsociate from "components/Header/ConturiAsociate";

export default function Header() {
  const { locDeConsumShowing, setLocDeConsumShowing, userLayoutInfo } =
    useContext(AppContext);
  const location = useLocation();
  const navigation = useNavigate();
  const [areConturiAsociate, setAreConturiAsociate] = useState(false);

  useEffect(() => {
    setAreConturiAsociate(userLayoutInfo.data?.associated_accounts?.length > 1);
  }, [userLayoutInfo]);

  return (
    <div className="page-layout-header">
      <div className="notifications">
        <NotificationCards />
      </div>

      <div className="welcome-and-icons-container">
        {!locDeConsumShowing.isShowing ? (
          <WelcomeContainer />
        ) : (
          <LocDeConsumContainer
            locDeConsumShowing={locDeConsumShowing}
            setLocDeConsumShowing={setLocDeConsumShowing}
          />
        )}

        <div className="pageName-and-text">
          <img src={LOGO} alt="logo" onClick={() => navigation("/dashboard")} />
          <p>{styleHelpers.getHeaderTitle(location)}</p>
        </div>

        <div className="icon-notification-profile-container">
          {/* <div className="header-notification-icon">
            <div className="header-notification-icon-container">
              <ICON_NOTIFICATION />
            </div>
            <div className="icon-notification-white-bg">
              <div className="icon-notification-red-circle" />
            </div>
          </div> */}

          {areConturiAsociate && (
            <ConturiAsociate
              users={userLayoutInfo?.data?.associated_accounts}
            />
          )}

          <EditProfileMenu />
        </div>
      </div>
    </div>
  );
}
