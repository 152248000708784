
const optionsTipFurnizare = [
   { value: "TOATE", label: "Toate" },
   { value: "Gaze", label: "Gaz" },
   { value: "Electricitate", label: "Electricitate" },
];

const optionsTipFurnizareEng = [
   { value: "TOATE", label: "Tip Furnizare" },
   { value: "gas", label: "Gaz" },
   { value: "energy", label: "Electricitate" },
];

const optionsLocDeConsum = [
   { value: "LOC1", label: "Locul 1 de consum" },
   { value: "LOC2", label: "Locul 2 de consum" },
   {
      value: "LOC3",
      label: "Cluj-Napoca, str. Observatorului nr. 34, bl. 3, sc. A, ap. 4",
   },
];

const optionsStatusPlata = [
   { value: "TOATE", label: "Toate" },
   { value: "paid", label: "Achitat" },
   { value: "unpaid", label: "De plata" },
   { value: "storno", label: "Storno" },
];

const optionsMonth = [
   { value: "TOATE", label: "Toate" },
   { value: "LAST6MONTHS", label: "Ultimele 6 luni" },
   { value: "LAST12MONTHS", label: "Ultimele 12 luni" },
];

const optionsSingleMonth = [
   { value: "IANUARIE", label: "Luna: 01.2023" },
   { value: "FEBRUARIE", label: "Luna: 02.2023" },
   { value: "MARTIE", label: "Luna: 03.2023" },
   { value: "APRILIE", label: "Luna: 04.2023" },
   { value: "MAI", label: "Luna: 05.2023" },
   { value: "IUNIE", label: "Luna: 06.2023" },
   { value: "IULIE", label: "Luna: 07.2023" },
   { value: "AUGUST", label: "Luna: 08.2023" },
];

const optionsAn = [
   { value: "TOATE", label: "Toate" },
   { value: "2023", label: "2023" },
   { value: "2022", label: "2022" },
   { value: "2021", label: "2021" },
   { value: "2020", label: "2020" },
   { value: "2019", label: "2019" },
];

const optionsTipTichet = [
   { value: "TOATE", label: "Toate" },
   { value: "Sesizare", label: "Sesizare" },
   { value: "Solicitare", label: "Solicitare" },
   { value: "Reclamatie", label: "Reclamatie" },
];

const optionsStatusSolicitare = [
   { value: "TOATE", label: "Toate" },
   { value: "INCHIS", label: "Inchis" },
   { value: "DESCHIS", label: "Deschis" },
   { value: "IN_CURS", label: "In curs" },
   { value: "IN_ASTEPTARE", label: "In asteptare" },
   { value: "RESPINS", label: "Respins" },
];

const optionsStatusSolicitareEng = [
   { value: "TOATE", label: "Toate" },
   { value: "closed", label: "Inchis" },
   { value: "open", label: "Deschis" },
   { value: "in_progress", label: "In curs" },
   { value: "on_hold", label: "In asteptare" },
   { value: "rejected", label: "Respins" },
];


const optionsSolicitareReclamatie = [
   { value: 1, label: "Solicitare" },
   { value: 2, label: "Sesizare" },
   { value: 3, label: "Reclamatie" },
];

const keyMappingDetaliiCont = {
   account_name: "nume",
   partner_identifier_code: "cnp_cui_partener",
   user_identifier_code: "cnp_cui_utilizator",
   email: "email",
   phone_number: "telefon",

   country: "tara",
   county: "judet",
   city: "oras",
   street: "strada",
   street_number: "numar",
   building: "bloc",
   entrance: "scara",
   tronson: "tronson",
   floor: "etaj",
   apartment: "apartament",
   sector: "sector",
   postal_code: "cod_postal",
   observations: "observatii",
};

const keyMappingUtilizatori = {
   last_name: "nume",
   first_name: "prenume",
   email: "email",
   phone_number: "telefon",
};

const optionsUtilityType = [
   { value: "gas", label: "Gaz" },
   { value: "energy", label: "Energie electrica" },
];

const objectSchema = {
   optionsTipFurnizare,
   optionsLocDeConsum,
   optionsStatusPlata,
   optionsMonth,
   optionsSingleMonth,
   optionsAn,
   optionsTipTichet,
   optionsStatusSolicitare,
   optionsSolicitareReclamatie,
   keyMappingDetaliiCont,
   keyMappingUtilizatori,
   optionsUtilityType,
   optionsTipFurnizareEng,
   optionsStatusSolicitareEng,
};

export default objectSchema;
