import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { AppContext } from "App";
import { apiContulMeu } from "api/contulMeu.service";
import { helpers } from "helpers/helpers";
import { useFormik } from "formik";
import { modalUtilizatoriSchema } from "schemas/yupSchema";
import prepareData from "schemas/prepareDataSchema";
import { toast } from "react-toastify";
import TextInput from "components/TextInput";
import SubmitButton from "components/SubmitButton";
import { ReactComponent as ICON_CLOSE } from "assets/images/common/x_button.svg";
import objectSchema from "schemas/objectSchema";
import { isString } from "lodash";
export default function ModalUtilizatori({
  showUtilizatoriModal,
  setShowUtilizatoriModal,
  setContacts,
}) {
  const { setIsScreenLoading } = useContext(AppContext);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const headerText =
    showUtilizatoriModal.modalPurpose === "ADAUGA_UTILIZATOR"
      ? "Adaugă utilizator"
      : " Modifică utilizator";
  const submitButtonText =
    showUtilizatoriModal.modalPurpose === "ADAUGA_UTILIZATOR"
      ? "Adaugă"
      : "Salvează";

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: isFormSubmitted,
    initialValues: {
      prenume: showUtilizatoriModal.utilizator?.first_name ?? "",
      nume: showUtilizatoriModal.utilizator?.last_name ?? "",
      email: showUtilizatoriModal.utilizator?.email ?? "",
      telefon: showUtilizatoriModal.utilizator?.phone_number ?? "",
    },
    validationSchema: modalUtilizatoriSchema,
    onSubmit: async (values) => {
      helpers.scrollToTop();
      setIsScreenLoading(true);

      if (showUtilizatoriModal.modalPurpose === "ADAUGA_UTILIZATOR") {
        await createNewContact(values);
      }

      if (showUtilizatoriModal.modalPurpose === "MODIFICA_UTILIZATOR") {
        const areValuesTheSame = helpers.areObjectsEqual(
          showUtilizatoriModal.utilizator,
          values,
          objectSchema.keyMappingUtilizatori
        );
        if (areValuesTheSame.isEqual)
          toast.info("Nu s-a modificat niciun camp!");
        else await editContact(areValuesTheSame.nonMatching);
      }

      setShowUtilizatoriModal({
        show: false,
        modalPurpose: "",
        utilizator: null,
      });
      setIsScreenLoading(false);
    },
  });

  const editContact = async (values) => {
    const response = await apiContulMeu.editContact({
      ...values,
      contact_id: showUtilizatoriModal.utilizator.contact_id,
    });

    if (response.isSuccess) {
      setContacts((prevState) => ({
        ...prevState,
        fetchAgain: prevState.fetchAgain + 1,
      }));
      toast.success(response.response.message);
    }
  };

  const createNewContact = async (values) => {
    const dataToSend = prepareData.utilizatoriModal(values);
    try {
      const response = await apiContulMeu.createNewContact(dataToSend);
      console.log("🚀 ~ createNewContact ~ response:", response);

      if (response.isSuccess) {
        setContacts((prevState) => ({
          ...prevState,
          fetchAgain: prevState.fetchAgain + 1,
        }));
        toast.success(response.response.message);
      } else {
        if (isString(response.error)) {
          toast.error(response.error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {ReactDOM.createPortal(
        <div className="modifica-date-container">
          <div className="modifica-date-content">
            <div className="modifica-date-header">
              <h2>{headerText}</h2>
              <ICON_CLOSE
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setShowUtilizatoriModal({
                    show: false,
                    modalPurpose: "",
                    utilizator: null,
                  })
                }
              />
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="modifica-date-textInput-row">
                <h2>Prenume</h2>
                <TextInput
                  id={"prenume"}
                  name={"prenume"}
                  type={"text"}
                  placeholder={"Prenume"}
                  classNames={"modifica-date-modal-textInput"}
                  value={formik.values.prenume}
                  error={formik.errors.prenume}
                  touched={formik.touched.prenume}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              <div className="modifica-date-textInput-row">
                <h2>Nume</h2>
                <TextInput
                  id={"nume"}
                  name={"nume"}
                  type={"text"}
                  placeholder={"Nume"}
                  classNames={"modifica-date-modal-textInput"}
                  value={formik.values.nume}
                  error={formik.errors.nume}
                  touched={formik.touched.nume}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              <div className="modifica-date-textInput-row">
                <h2>Email</h2>
                <TextInput
                  id={"email"}
                  name={"email"}
                  type={"text"}
                  placeholder={"Email"}
                  classNames={"modifica-date-modal-textInput"}
                  value={formik.values.email}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              <div className="modifica-date-textInput-row">
                <h2>Telefon</h2>
                <TextInput
                  id={"telefon"}
                  name={"telefon"}
                  type={"text"}
                  placeholder={"Telefon"}
                  classNames={"modifica-date-modal-textInput"}
                  value={formik.values.telefon}
                  error={formik.errors.telefon}
                  touched={formik.touched.telefon}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              <div className="modifica-date-modal-end-buttons">
                <SubmitButton
                  onClick={() =>
                    setShowUtilizatoriModal({
                      show: false,
                      modalPurpose: "",
                      utilizator: null,
                    })
                  }
                  classNames={"buton-bg-alb-green"}
                  text={"Inchide"}
                />
                <SubmitButton
                  onClick={() => setIsFormSubmitted(true)}
                  typeOfButton={"submit"}
                  text={submitButtonText}
                />
              </div>
            </form>
          </div>
        </div>,
        document.getElementById("modifica-date-modal")
      )}
    </>
  );
}
