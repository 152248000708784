export function getActiveNotificationPattern(notification) {
    // Check if `notification` exists
    if (
      !notification ||
      typeof notification.dates !== "string" ||
      notification.dates.trim().length === 0
    ) {
      return { enabled: false, patterns: "" }; // Return object indicating no active patterns
    }
  
    const today = new Date().getDate();
    const endOfMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).getDate(); // Get last day of the current month
  
    const datePatterns = notification.dates.trim(); // Ensure `dates` is a trimmed string
    const patternsArray = datePatterns.split("|");
  
    let patternString = [];
    let enabled = false;
    // Proceed only if `patternsArray` is an array with at least one pattern
    if (Array.isArray(patternsArray) && patternsArray.length > 0) {
      patternsArray.forEach((pattern) => {
        if (pattern.includes("-")) {
          const [start, end] = pattern.split("-").map(Number);
          patternString.push(`în intervalul calendaristic ${start}-${end}`);
          if (today >= start && today <= end) {
            enabled = true;
          }
        } else if (pattern.startsWith("first")) {
          const firstDays = parseInt(pattern.slice(5), 10);
          if (firstDays === 1) {
            patternString.push(`în prima zi a lunii`);
          } else {
            patternString.push(`în primele ${firstDays} zile ale lunii`);
          }
  
          if (today <= firstDays) {
            enabled = true;
          }
        } else if (pattern.startsWith("last")) {
          const lastDays = parseInt(pattern.slice(4), 10);
  
          if (lastDays === 1) {
            patternString.push(`în ultima zi a lunii`);
          } else {
            patternString.push(`în ultimele ${lastDays} zile ale lunii`);
          }
  
          if (today > endOfMonth - lastDays) {
            enabled = true;
          }
        }
      });
    }
    let patterns = "";
    if (patternString.length === 1) {
      patterns = patternString[0];
    } else if (patternString.length === 2) {
      patterns = patternString.join(" si ");
    } else if (patternString.length >= 3) {
      patterns = `${patternString.slice(0, -1).join(", ")} si ${
        patternString[patternString.length - 1]
      }`;
    }
  
    return {
      enabled,
      patterns: `Puteți trimite consumul doar ${patterns}.`,
    };
  };