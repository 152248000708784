import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { storageHelpers } from "helpers/storageHelpers";

export default function LoggedInUser() {
  const isUserLoggedIn = storageHelpers.isUserLoggedIn();

  useEffect(() => {
    if (!isUserLoggedIn) toast.error("Trebuie sa te autentifici!");
  }, [isUserLoggedIn]);

  return isUserLoggedIn ? <Outlet /> : <Navigate to="/" replace />;
}
