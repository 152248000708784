import React, { useState, useEffect } from "react";
import { apiContulMeu } from "api/contulMeu.service";
import UtilizatoriCard from "components/ContulMeu/UtilizatoriCard";
import ComponentLoading from "components/Loaders/ComponentLoading";
import SubmitButton from "components/SubmitButton";
import PLUS from "assets/images/common/plus.svg";
import ModalUtilizatori from "components/ContulMeu/Modal/ModalUtilizatori";

export default function Utilizatori() {
  const [contacts, setContacts] = useState({
    data: [],
    fetchAgain: 0,
  });
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [showUtilizatoriModal, setShowUtilizatoriModal] = useState({
    show: false,
    modalPurpose: "",
    utilizator: null,
  });

  useEffect(() => {
    const getMyContacts = async () => {
      setIsDataFetching(true);
      const response = await apiContulMeu.myContacts();
      if (response.isSuccess) {
        setContacts((prevState) => ({
          ...prevState,
          data: response.response.data,
        }));
        setIsDataFetching(false);
      }
    };
    getMyContacts();
  }, [contacts.fetchAgain]);

  if (showUtilizatoriModal.show) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "auto";

  return (
    <>
      <div className="utilizatori-div">
        <div className="utilizatori-header">
          <h2>Utilizatori</h2>
          {!isDataFetching && (
            <SubmitButton
              onClick={() =>
                setShowUtilizatoriModal({
                  show: true,
                  modalPurpose: "ADAUGA_UTILIZATOR",
                  utilizator: null,
                })
              }
              classNames={"buton-bg-alb-green utilizatori-btn"}
              text={"Adauga utilizator"}
              icon={PLUS}
            />
          )}
        </div>
        <div className="utilizatori-content">
          {isDataFetching ? (
            <ComponentLoading />
          ) : (
            <>
              {contacts.data.map((contact, index) => {
                const isLastItem = index === contacts.length - 1;
                return (
                  <UtilizatoriCard
                    key={contact.contact_id}
                    item={contact}
                    isLastItem={isLastItem}
                    setShowUtilizatoriModal={setShowUtilizatoriModal}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
      {showUtilizatoriModal.show && (
        <ModalUtilizatori
          showUtilizatoriModal={showUtilizatoriModal}
          setShowUtilizatoriModal={setShowUtilizatoriModal}
          setContacts={setContacts}
        />
      )}
    </>
  );
}
