import { requestJson, RequestMethod } from "helpers/httpHelper";
import {API_URL} from "constants/api.constants";
import axios from "axios";

const login = async (email, password) => {
  try {
   const response = await axios.post(API_URL + "user/login", {
      email,
      password
    })

    return { response: response.data, isSuccess: true, error: null };
  } catch (ex) {
    return { response: null, isSuccess: false, error: ex?.message ?? ex };
  }
};

const logout = async () => {
  const response = await requestJson("user/logout", RequestMethod.GET, {
    useAccessToken: true
  });
  return response;
};

const changePassword = async (currentPassword, newPassword, newPasswordConfirmation) => {
  const response = await requestJson(
    "user/change-password",
    RequestMethod.PATCH,
    {
      useAccessToken: true,
      data: {
          password: newPassword,
          password_confirmation: newPasswordConfirmation,
          current_password: currentPassword},
    }
  );
  return response;
};

const switchUser = async (user) => {
    const response = await requestJson(
        "account",
        RequestMethod.PATCH,
        {
            useAccessToken: true,
            data: {
                client_code: user.client_code,
            }
        }
    );
    return response;
};

const resetPassword = async (token, contact_id, email, password, password_confirmation) => {
    const response = await requestJson(
        "user/reset-password",
        RequestMethod.POST,
        {
            data: {
                token: token,
                contact_id: contact_id,
                email: email,
                password: password,
                password_confirmation: password_confirmation
            }
        }
    );
    return response;
};

const sendRecoverPasswordLink = async (email) => {
    const response = await requestJson(
        "user/reset-password/link",
        RequestMethod.POST,
        {
            data: {
                email: email,
            }
        }
    );

    return response;
};




export const AuthService = {
  login,
  logout,
  changePassword,
    switchUser,
    resetPassword,
    sendRecoverPasswordLink
};
