import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "api/auth.service";
import { storageHelpers } from "helpers/storageHelpers";
import { styleHelpers } from "helpers/styleHelpers";

import { ReactComponent as ICON_PROFILE } from "assets/images/menu/icon_profile.svg";

export default function EditProfileMenu() {
  const location = useLocation();
  const navigation = useNavigate();

  const handleLogout = async () => {
    const response = await AuthService.logout();
    if (response.isSuccess) {
      localStorage.clear();
      // storageHelpers.removeUserToken();
      navigation("/");
    }
  };

  return (
    <div className="header-edit-profile">
      <div className="header-edit-profile-icon-container">
        <ICON_PROFILE />
      </div>

      <div className="header-edit-profile-content">
        <div
          onClick={() => navigation("/contul-meu")}
          className={`${styleHelpers.getEPDivClass(location, "/contul-meu")}`}
        >
          <p>Detalii cont</p>
        </div>
        {/*<div*/}
        {/*  onClick={() => navigation("/recomandari")}*/}
        {/*  className={`${styleHelpers.getEPDivClass(location, "/recomandari")}`}*/}
        {/*>*/}
        {/*  <p>Recomandari</p>*/}
        {/*</div>*/}
        <div
          onClick={() => navigation("/suport")}
          className={`${styleHelpers.getEPDivClass(location, "/suport")}`}
        >
          <p>Suport</p>
        </div>
        <div onClick={() => handleLogout()} className="header-edit-profile-div">
          <p>Log out</p>
        </div>
      </div>
    </div>
  );
}
