import { requestJson, RequestMethod } from "helpers/httpHelper";
import prepareData from "schemas/prepareDataSchema";

const getAutocitireTableData = async () => {
   const response = await requestJson("selfreadings", RequestMethod.GET, {
      useAccessToken: true,
   });
   return response;
};

const addNewAutocitire = async data => {
   const response = await requestJson("selfreadings", RequestMethod.POST, {
      useAccessToken: true,
      data: prepareData.adaugaAutocitire(data),
   });
   return response;
};

export const apiAutocitire = {
   getAutocitireTableData,
   addNewAutocitire,
};
