const setUserToken = (token) => {
  const user = { token };
  localStorage.setItem("userNovaMyAccount", JSON.stringify(user));
};

const getUserToken = () => {
  const user = JSON.parse(localStorage.getItem("userNovaMyAccount"));
  if (user && user.token) return user.token;
  return null;
};

const removeUserToken = () => {
  localStorage.removeItem("userNovaMyAccount");
};

const isUserLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem("userNovaMyAccount"));
  if (user) return true;
  return false;
};

const setUserAccount = (account) => {
  localStorage.setItem("account", JSON.stringify(account));
};

const getUserAccount = () => {
  const account = JSON.parse(localStorage.getItem("account"));
  if (account) return account;
  return null;
};

const setLocuriDeConsum = (locuriDeConsum) => {
  localStorage.setItem("locuriDeConsum", JSON.stringify(locuriDeConsum));
};

const getLocuriDeConsum = () => {
  const locuriDeConsum = JSON.parse(localStorage.getItem("locuriDeConsum"));
  if (locuriDeConsum) return locuriDeConsum;
  return null;
};

export const storageHelpers = {
  setUserToken,
  getUserToken,
  removeUserToken,
  isUserLoggedIn,
  getUserAccount,
  setUserAccount,
  setLocuriDeConsum,
  getLocuriDeConsum,
};
