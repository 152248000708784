import SelectInput from "components/SelectInput";
import SubmitButton from "components/SubmitButton";
import objectSchema from "schemas/objectSchema";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";

const tableTipFurnizare = (rowData) => {
  return (
    <div className="facturileMele-table-tipFurnizare">
      <img
        src={rowData.utility_type === "gas" ? GAZ : CURENT}
        alt="icon_gaz_curent"
      />
    </div>
  );
};

const tableScadent = (rowData) => {
  return (
    <div className="facturileMele-table-scadent">
      <p>{rowData.invoice_due_date}</p>
    </div>
  );
};

const tableValoare = (rowData) => {
  return (
    <div className="facturileMele-table-valoare">
      <p>{rowData.amount} Lei</p>
    </div>
  );
};

const header = (
  locuriConsum,
  filteredFacturileMeleTableData,
  setFilteredFacturileMeleTableData,
  exportXLSX,
  facturileMeleTableData,
  handleChangeFilter,
  handleChangeLocDeConsum,
  selectedTipFurnizare,
  selectedLocDeConsum,
  selectedStatus
) => {
  return (
    <div className="facturileMele_table_header">
      <p>{filteredFacturileMeleTableData.length} facturi</p>
      <div className="facturileMele-table-row-selectors">
        <SelectInput
          reactSelectClassName="facturileMele-table-tipFurnizare"
          isLabel={false}
          value={selectedTipFurnizare}
          onChange={(value) => {
            handleChangeFilter(
              value.value,
              "utility_type",
              facturileMeleTableData,
              setFilteredFacturileMeleTableData
            );
          }}
          placeholder="Tip furnizare"
          options={objectSchema.optionsTipFurnizareEng}
        />
        <SelectInput
          reactSelectClassName="facturileMele-table-locDeConsum"
          isLabel={false}
          value={selectedLocDeConsum}
          onChange={(value) => {
            handleChangeLocDeConsum(
              value.value,
              facturileMeleTableData,
              setFilteredFacturileMeleTableData
            );
          }}
          placeholder="Loc de consum"
          options={[
            { value: "TOATE", label: "Toate locurile de consum" },
            ...locuriConsum.map((item) => ({
              value: item.metering_point_id,
              label: (
                <div>
                  <img
                    alt="icon_gaz_curent"
                    src={item.utility_type === "Gaze" ? GAZ : CURENT}
                    height={"30px"}
                  />
                  {" " + item.address_summary}
                </div>
              ),
            })),
          ]}
        />
        <SelectInput
          reactSelectClassName="facturileMele-table-status"
          isLabel={false}
          value={selectedStatus}
          onChange={(value) => {
            handleChangeFilter(
              value.value,
              "status",
              facturileMeleTableData,
              setFilteredFacturileMeleTableData
            );
          }}
          placeholder="Status"
          options={objectSchema.optionsStatusPlata}
        />
      </div>
      <SubmitButton
        text="Export"
        classNames={"buton-bg-alb-green buton_facturileMele-export"}
        onClick={exportXLSX}
        data-pr-tooltip="XLSX"
      />
    </div>
  );
};

export const tableFacturileMeleHelper = {
  header,
  tableTipFurnizare,
  tableScadent,
  tableValoare,
};
