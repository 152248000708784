import ARROR_RIGHT from "assets/images/notifications/info.svg";
const NotificationCard = ({ content = "" }) => {
  return (
    <div className="one-notification-div">
      <p> {content}</p> <img src={ARROR_RIGHT} alt="arrow_right" />
    </div>
  );
};

export default NotificationCard;
