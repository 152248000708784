import React, { useState } from "react";
import Layout from "pages/Layout";
import DataTableFacturileMele from "components/FacturiSiPlati/DataTableFacturileMele";
import DataTableIstoricPlati from "components/FacturiSiPlati/DataTableIstoricPlati";
import DataTableTransmitereFactura from "components/FacturiSiPlati/DataTableTransmitereFactura";

export default function FacturiSiPlati({
  isDataFetching,
  facturileMeleTableData,
}) {
  const [selectedOption, setSelectedOption] = useState("FACTURILE_MELE");

  const mapTable = {
    FACTURILE_MELE: (
      <DataTableFacturileMele
        isDataFetching={isDataFetching}
        facturileMeleTableData={facturileMeleTableData}
        dashboard={true}
      />
    ),
    ISTORIC_PLATI_ONLINE: <DataTableIstoricPlati />,
    TRANSMITERE_FACTURA: <DataTableTransmitereFactura />,
  };

  return (
    <Layout>
      <div className="facturi_si_plati-selector-row">
        <div className="facturi_si_plati-selectors">
          <div
            onClick={() => setSelectedOption("FACTURILE_MELE")}
            className={
              selectedOption === "FACTURILE_MELE"
                ? "facturi_si_plati-option facturi_si_plati-option-selected"
                : "facturi_si_plati-option"
            }
          >
            <p>Facturile mele</p>
          </div>
          <div
            onClick={() => setSelectedOption("ISTORIC_PLATI_ONLINE")}
            className={
              selectedOption === "ISTORIC_PLATI_ONLINE"
                ? "facturi_si_plati-option facturi_si_plati-option-selected"
                : "facturi_si_plati-option"
            }
          >
            <p>Istoric plati online</p>
          </div>
          <div
            onClick={() => setSelectedOption("TRANSMITERE_FACTURA")}
            className={
              selectedOption === "TRANSMITERE_FACTURA"
                ? "facturi_si_plati-option facturi_si_plati-option-selected"
                : "facturi_si_plati-option"
            }
          >
            <p>Transmitere factura</p>
          </div>
        </div>
        <div className="autocitire-table-line" />
      </div>

      {mapTable?.[selectedOption] || (
        <DataTableFacturileMele
          isDataFetching={isDataFetching}
          facturileMeleTableData={facturileMeleTableData}
        />
      )}
    </Layout>
  );
}
