import React, { useContext } from "react";
import { AppContext } from "App";
import { useLocation, useNavigate } from "react-router-dom";
import { styleHelpers } from "helpers/styleHelpers";

import MENU_LOGO from "assets/images/menu/menu_logo.png";
import { ReactComponent as ICON_ACASA } from "assets/images/menu/icon_acasa.svg";
import { ReactComponent as ICON_FACTURI } from "assets/images/menu/icon_facturi.svg";
import { ReactComponent as ICON_AUTOCITIRE } from "assets/images/menu/icon_autocitire.svg";
import { ReactComponent as ICON_LOCURI } from "assets/images/menu/icon_locuri.svg";
import { ReactComponent as ICON_RAPOARTE } from "assets/images/menu/icon_rapoarte.svg";
import { ReactComponent as ICON_SUPORT } from "assets/images/menu/icon_suport.svg";

export default function LeftMenu() {
  const { setLocDeConsumShowing } = useContext(AppContext);

  const navigation = useNavigate();
  const location = useLocation();

  const handleClickMenu = (path) => {
    navigation(path);
    setLocDeConsumShowing({
      id: null,
      isShowing: false,
      locDeConsum: null,
    });
  };

  return (
    <div className="left-menu">
      <img src={MENU_LOGO} className="menu-logo" alt="menu_logo"
           onClick={() => handleClickMenu("/dashboard")}
      />
      <div
        onClick={() => handleClickMenu("/dashboard")}
        className={styleHelpers.getLeftMenuClass(location, "/dashboard")}
      >
        <div className="menu-icon-container">
          <ICON_ACASA />
        </div>
        <p>Acasa</p>
      </div>
      <div
        onClick={() => handleClickMenu("/facturi-si-plati")}
        className={styleHelpers.getLeftMenuClass(location, "/facturi-si-plati")}
      >
        <div className="menu-icon-container">
          <ICON_FACTURI />
        </div>
        <p>Facturi si plati</p>
      </div>
      <div
        onClick={() => handleClickMenu("/autocitire")}
        className={styleHelpers.getLeftMenuClass(location, "/autocitire")}
      >
        <div className="menu-icon-container">
          <ICON_AUTOCITIRE />
        </div>
        <p>Autocitire</p>
      </div>
      <div
        onClick={() => handleClickMenu("/locuri-de-consum")}
        className={styleHelpers.getLeftMenuClass(location, "/locuri-de-consum")}
      >
        <div className="menu-icon-container">
          <ICON_LOCURI />
        </div>
        <p>Locuri de consum</p>
      </div>
      <div
        onClick={() => handleClickMenu("/rapoarte")}
        className={styleHelpers.getLeftMenuClass(location, "/rapoarte")}
      >
        <div className="menu-icon-container">
          <ICON_RAPOARTE />
        </div>
        <p>Rapoarte</p>
      </div>
      <div
        onClick={() => handleClickMenu("/suport")}
        className={styleHelpers.getLeftMenuClass(location, "/suport")}
      >
        <div className="menu-icon-container">
          <ICON_SUPORT />
        </div>
        <p>Suport</p>
      </div>
    </div>
  );
}
