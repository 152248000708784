import { format } from "date-fns";

const deschideTicket = data => {
   return {
      incident_type: data.tipTichet,
      utility_type_id: data.tipFurnizare,
      subject_id: data.subiect,
      metering_point_id: data.locDeConsum,
      data: format(data.dataProblemei, "yyyy-MM-dd"),
      title: data.title
   };
};

const utilizatoriModal = data => {
   return {
      first_name: data.prenume,
      last_name: data.nume,
      email: data.email,
      phone_number: data.telefon,
   };
};

const patchAccountDetails = data => {
   let { county, city, street, postal_code, ...restData } = data;

   let newObj = { ...restData };

   if (county) newObj.county_id = county;
   if (city) newObj.city_id = city;
   if (street) newObj.street_id = street;
   if (postal_code) newObj.postal_code_id = postal_code;

   return newObj;
};

const adaugaAutocitire = data => {
   return {
      ...data,
      date: format(new Date(data.date), "yyyy-MM-dd"),
      total_consumption: parseFloat(data.total_consumption),
   };
};

const prepareData = {
   deschideTicket,
   utilizatoriModal,
   patchAccountDetails,
   adaugaAutocitire,
};

export default prepareData;
