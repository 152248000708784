import SubmitButton from "components/SubmitButton";
import SelectInput from "components/SelectInput";
import objectSchema from "schemas/objectSchema";

import ARROW_FORWARD from "assets/images/common/arrow_forward.svg";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";

const tableTip = (rowData) => {
  return (
    <div
      className={`locuriDeConsum-table-imageContainer ${
        rowData.utility_type === "Electricitate" && "curent-background"
      }`}
    >
      <img
        src={rowData.utility_type === "Electricitate" ? CURENT : GAZ}
        alt="icon_gaz_curent"
      />
    </div>
  );
};

const tableLocatie = (rowData) => {
  return (
    <div className="locuriDeConsum-table-locatie">
      <p>{rowData.address_summary}</p>
      <p className="locuriDeConsum-table-locatie-tipCumparator">
        {rowData.contract_type}
      </p>
    </div>
  );
};

const tableLocatieCompleta = (rowData) => {
  return (
    <div className="locuriDeConsum-table-locatieCompleta">
      <p>{rowData.address}</p>
    </div>
  );
};

const tableButton = (rowData, setLocDeConsumShowing) => {
  return (
    <SubmitButton
      onClick={() =>
        setLocDeConsumShowing({
          id: rowData.metering_point_id,
          isShowing: true,
          locDeConsum: rowData.address_summary,
        })
      }
      icon={ARROW_FORWARD}
      classNames={"buton-bg-alb-green locuriDeConsum-table-export-button"}
    />
  );
};

const handleChangeTipFurnizare = (
  value,
  locDeConsumTableData,
  setFilteredLocDeConsumTableData
) => {
  if (value !== "TOATE") {
    const filteredLocDeConsumTableData = locDeConsumTableData.filter(
      (item) => item.utility_type === value
    );
    setFilteredLocDeConsumTableData(filteredLocDeConsumTableData);
  } else {
    setFilteredLocDeConsumTableData(locDeConsumTableData);
  }
};

const header = (
  tipFurnizare,
  setTipFurnizare,
  locDeConsumTableData,
  setFilteredLocDeConsumTableData,
  exportXLSX
) => {
  return (
    <div className="locuriDeConsum_table_header">
      <h2>Locuri de consum</h2>
      {/* <div className="autocitire-table-line" /> */}
      <div className="autocitire-table-autocitiri-export">
        <div className="locuriDeConsum-table-header-two-items">
          <p>{locDeConsumTableData.length} inregistrari</p>
          <SelectInput
            reactSelectClassName="locuriDeConsum-table-select"
            isLabel={false}
            value={tipFurnizare}
            onChange={(value) => {
              handleChangeTipFurnizare(
                value.value,
                locDeConsumTableData,
                setFilteredLocDeConsumTableData
              );
              setTipFurnizare(value.value);
            }}
            placeholder="Selectează..."
            options={objectSchema.optionsTipFurnizare}
          />
        </div>

        <SubmitButton
          classNames={"buton-bg-alb-green autocitire-table-export-button"}
          text={"Export"}
          onClick={exportXLSX}
          data-pr-tooltip="CSV"
        />
      </div>
    </div>
  );
};

export const tableLocuriDeConsumHelper = {
  header,
  tableTip,
  tableButton,
  tableLocatie,
  tableLocatieCompleta,
};
