import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "App";
import { useFormik } from "formik";
import { recoverPasswordSchema} from "schemas/yupSchema";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "api/auth.service";
import { storageHelpers } from "helpers/storageHelpers";
import { toast } from "react-toastify";
import TextInput from "components/TextInput";
import SubmitButton from "components/SubmitButton";
import DOWN_BACKGROUND from "assets/images/login/login-down-background.png";
import BACKGROUND from "assets/images/login/background.png";
import LOGO from "assets/images/login/logo.png";
import BACKGROUND_MOBILE_TOP from "assets/images/login/background_mobile_top.png";
import BACKGROUND_MOBILE_BOTTOM from "assets/images/login/background_mobile_bottom.png";

export default function RecoverPassword() {
  const { setIsScreenLoading } = useContext(AppContext);
  const navigation = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: isFormSubmitted,
    initialValues: {
      email: "",
    },
    validationSchema: recoverPasswordSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setIsScreenLoading(true);

    const response = await AuthService.sendRecoverPasswordLink(values.email);
    if (response.isSuccess) {
      navigation("/");
      toast.success("Vei primi pe email un link de resetare parola. Te rugam sa verifici email-ul.");
    }else{
        toast.error("A aparut o eroare. Va rugam sa ne contactati.");
    }

    setIsScreenLoading(false);
  };

  return (
    <div className="login-page">
      <div className="login-main-container">
        <img src={LOGO} className="login-logo" alt="logo" />
        <h1>Recuperare parola</h1>
        <form className="login-form" onSubmit={formik.handleSubmit}>
          <div className="login-input-row">
            <TextInput
                isLoginInput={true}
                id={"email"}
                name={"email"}
                type={"text"}
                placeholder={"Email"}
                classNames={"login-textInput"}
                value={formik.values.email}
                error={formik.errors.email}
                touched={formik.touched.email}
                setFieldValue={formik.setFieldValue}
            />
          </div>
            <a     onClick={() => navigation("/")}>Inapoi la autentificare</a>
          <SubmitButton
            onClick={() => setIsFormSubmitted(true)}
            typeOfButton={"submit"}
            text={"Trimite link de resetare"}
            classNames={"login-button"}
          />
        </form>
      </div>
      <img
        src={DOWN_BACKGROUND}
        className="login-down-background"
        alt="down-background"
      />
      <img src={BACKGROUND} className="login-background" alt="background" />
      <img
        src={BACKGROUND_MOBILE_TOP}
        className="login-background-mobile-top"
        alt="background-mobile-top"
      />
      <img
        src={BACKGROUND_MOBILE_BOTTOM}
        className="login-background-mobile-bottom"
        alt="background-mobile-bottom"
      />
    </div>
  );
}
