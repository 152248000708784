import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { AppContext } from "App";
import { apiNomenclature } from "api/nomenclature.service";
import { apiContulMeu } from "api/contulMeu.service";
import { helpers } from "helpers/helpers";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import objectSchema from "schemas/objectSchema";
import prepareData from "schemas/prepareDataSchema";
import TabDatePersonale from "components/ContulMeu/Modal/TabDatePersonale";
import TabAdresa from "components/ContulMeu/Modal/TabAdresa";
import { ReactComponent as ICON_CLOSE } from "assets/images/common/x_button.svg";

export default function ModalModificaDate({
  userDetails,
  setUserDetails,
  setShowModificaDate,
}) {
  const { setIsScreenLoading, setUserLayoutInfo } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState("DATE_PERSONALE");
  const [optionCountries, setOptionCountries] = useState([]);
  const [optionCounties, setOptionCounties] = useState([]);
  const [optionCities, setOptionCities] = useState([]);
  const [optionStreets, setOptionStreets] = useState([]);
  const [optionPostalCodes, setOptionPostalCodes] = useState([]);
  const [selectedKeyFromOptionsCountry, setSelectedKeyFromOptionsCountry] =
    useState("value");
  const [selectedKeyFromOptionsCounty, setSelectedKeyFromOptionsCounty] =
    useState("value");
  const [selectedKeyFromOptionsCity, setSelectedKeyFromOptionsCity] =
    useState("value");
  const [selectedKeyFromOptionsStreet, setSelectedKeyFromOptionsStreet] =
    useState("value");
  const [
    selectedKeyFromOptionsPostalCode,
    setSelectedKeyFromOptionsPostalCode,
  ] = useState("value");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nume: userDetails.data.account_name,
      cnp_cui_partener: userDetails.data.partner_identifier_code,
      cnp_cui_utilizator: userDetails.data.user_identifier_code,
      email: userDetails.data.email,
      telefon: userDetails.data.phone_number,

      tara: userDetails.data.country,
      judet: userDetails.data.county,
      oras: userDetails.data.city,
      strada: userDetails.data.street,
      numar: userDetails.data.street_number,
      bloc: userDetails.data.building,
      scara: userDetails.data.entrance,
      tronson: userDetails.data.tronson,
      etaj: userDetails.data.floor,
      apartament: userDetails.data.apartment,
      sector: userDetails.data.sector,
      cod_postal: userDetails.data.postal_code,
      observatii: userDetails.data.observations,
    },
    // validationSchema: loginSchema,
    onSubmit: async (values) => {
      const areValuesTheSame = helpers.areObjectsEqual(
        userDetails.data,
        values,
        objectSchema.keyMappingDetaliiCont
      );

      if (areValuesTheSame.isEqual) toast.info("Nu s-a modificat niciun camp!");
      else await patchValues(areValuesTheSame.nonMatching);

      setShowModificaDate(false);
    },
  });

  const patchValues = async (values) => {
    helpers.scrollToTop();
    setIsScreenLoading(true);

    const dataToSend = prepareData.patchAccountDetails(values);

    const response = await apiContulMeu.patchAllAccountDetails(dataToSend);

    if (response.isSuccess) {
      setUserDetails((prevState) => ({
        ...prevState,
        fetchAgain: prevState.fetchAgain + 1,
      }));
      setUserLayoutInfo((prevState) => ({
        ...prevState,
        fetchAgain: prevState.fetchAgain + 1,
      }));
      toast.success("Datele au fost modificate cu succes!");
    }
    // else toast.error("A aparut o eroare!");
    setIsScreenLoading(false);
  };

  useEffect(() => {
    const getCountries = async () => {
      const response = await apiNomenclature.getCountries();
      if (response.isSuccess) {
        setOptionCountries(
          response.response.data.map((item) => ({
            value: item.country,
            label: item.country,
            id: item.country_id,
          }))
        );
      }
    };
    getCountries();

    const getCounties = async () => {
      const response = await apiNomenclature.getCounties();
      if (response.isSuccess) {
        setOptionCounties(
          response.response.data.map((item) => ({
            value: item.county,
            label: item.county,
            id: item.county_id,
          }))
        );
      }
    };
    getCounties();

    const getInitialCities = async () => {
      const response = await apiNomenclature.getCitiesByCounty(
        userDetails.data.county_id
      );
      if (response.isSuccess) {
        setOptionCities(
          response.response.data.map((item) => ({
            value: item.city,
            label: item.city,
            id: item.city_id,
          }))
        );
      }
    };
    if (formik.values.tara && userDetails.data.county_id) getInitialCities();

    const getInitialStreets = async () => {
      const response = await apiNomenclature.getStreetsByCity(
        userDetails.data.city_id
      );
      if (response.isSuccess) {
        setOptionStreets(
          response.response.data.map((item) => ({
            value: item.street,
            label: item.street,
            id: item.street_id,
          }))
        );
      }
    };
    if (formik.values.strada && userDetails.data.city_id) getInitialStreets();

    const getInitialPostalCodes = async () => {
      const response = await apiNomenclature.getPostalCodesByStreet(
        userDetails.data.street_id
      );
      if (response.isSuccess) {
        setOptionPostalCodes(
          response.response.data.map((item) => ({
            value: item.postal_code,
            label: item.postal_code,
            id: item.postal_code_id,
          }))
        );
      }
    };
    if (formik.values.cod_postal && userDetails.data.street_id)
      getInitialPostalCodes();
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <div className="modifica-date-container">
          <div className="modifica-date-content">
            <div className="modifica-date-header">
              <h2>Detalii cont</h2>
              <ICON_CLOSE
                style={{ cursor: "pointer" }}
                onClick={() => setShowModificaDate(false)}
              />
            </div>

            <div className="modifica-date-row-selector">
              <div
                onClick={() => setSelectedTab("DATE_PERSONALE")}
                className={`modifica-date-tab ${
                  selectedTab === "DATE_PERSONALE" &&
                  "modifica-date-tab-selected"
                }`}
              >
                <p>Date personale</p>
              </div>
              <div
                onClick={() => setSelectedTab("ADRESA")}
                className={`modifica-date-tab ${
                  selectedTab === "ADRESA" && "modifica-date-tab-selected"
                }`}
              >
                <p>Adresa</p>
              </div>
              <div className="autocitire-table-line" />
            </div>

            {selectedTab === "DATE_PERSONALE" && (
              <TabDatePersonale
                formik={formik}
                setShowModificaDate={setShowModificaDate}
              />
            )}

            {selectedTab === "ADRESA" && (
              <TabAdresa
                formik={formik}
                setShowModificaDate={setShowModificaDate}
                stateOfOptions={{
                  optionCountries,
                  optionCounties,
                  optionCities,
                  optionStreets,
                  optionPostalCodes,
                  setOptionCountries,
                  setOptionCounties,
                  setOptionCities,
                  setOptionStreets,
                  setOptionPostalCodes,
                }}
                stateOfselectedKeyFromOptions={{
                  selectedKeyFromOptionsCountry,
                  selectedKeyFromOptionsCounty,
                  selectedKeyFromOptionsCity,
                  selectedKeyFromOptionsStreet,
                  selectedKeyFromOptionsPostalCode,
                  setSelectedKeyFromOptionsCountry,
                  setSelectedKeyFromOptionsCounty,
                  setSelectedKeyFromOptionsCity,
                  setSelectedKeyFromOptionsStreet,
                  setSelectedKeyFromOptionsPostalCode,
                }}
              />
            )}
          </div>
        </div>,
        document.getElementById("modifica-date-modal")
      )}
    </>
  );
}
