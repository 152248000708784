import React from "react";
import ReactECharts from "echarts-for-react";
import graphData from "data/graphData";
import RapoarteInfoText from "components/Rapoarte/RapoarteInfoText";

export default function TabRaportSimplificat() {
  return (
    <>
      <div className="rapoarte-grafic-simplificat">
        <div className="rapoarte-grafic-simplificat-chart-and-header">
          <div className="rapoarte-grafic-simplificat-title-row">
            <h2>Energie electrica</h2>
            <p>Consum energie electrica 2023 comparat cu 2022</p>
          </div>
          <ReactECharts
            option={graphData.optionsRSEnergieElectrica}
            className="rapoarte-grafic-simplificat-chart"
          />
        </div>
        <RapoarteInfoText />
      </div>

      <div className="rapoarte-grafic-simplificat">
        <div className="rapoarte-grafic-simplificat-chart-and-header">
          <div className="rapoarte-grafic-simplificat-title-row">
            <h2>Gaze</h2>
            <p>Consum gaze 2023 comparat cu 2022</p>
          </div>
          <ReactECharts
            option={graphData.optionsRSGaze}
            className="rapoarte-grafic-simplificat-chart"
          />
        </div>
        <RapoarteInfoText />
      </div>
    </>
  );
}
