import React from "react";
import GRAPH_LOW from "assets/images/common/graph_low.svg";
import GRAPH_HIGH from "assets/images/common/graph_high.svg";

export default function RapoarteInfoText() {
  return (
    <div className="rapoarte-grafic-simplificat-info-container">
      <div className="rapoarte-grafic-simplificat-info">
        <h2>Consum mediu lunar in 2023</h2>
        <div className="d-flex">
          <p>1.48 MWh</p>
          <img src={GRAPH_LOW} alt="arrow" />
        </div>
      </div>

      <div className="rapoarte-grafic-simplificat-info">
        <h2>Factura medie lunara 2023</h2>
        <div className="d-flex">
          <p>27.25 RON</p>
          <img src={GRAPH_LOW} alt="arrow" />
        </div>
      </div>

      <div className="rapoarte-grafic-simplificat-info">
        <h2>Consum mediu zilnic in 2023</h2>
        <div className="d-flex">
          <p>0.25 MWh</p>
          <img src={GRAPH_HIGH} alt="arrow" />
        </div>
      </div>

      <div className="rapoarte-grafic-simplificat-info">
        <h2>Cost mediu zilnic in 2023</h2>
        <div className="d-flex">
          <p>2.54 RON</p>
          <img src={GRAPH_LOW} alt="arrow" />
        </div>
      </div>
    </div>
  );
}
