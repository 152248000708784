import React, { useEffect, useState } from "react";
import Layout from "pages/Layout";
import SelectInput from "components/SelectInput";
import TabRaportSimplificat from "components/Rapoarte/TabRaportSimplificat";
import TabRaportDetaliat from "components/Rapoarte/TabRaportDetaliat";
import { apiLocuriDeConsum } from "api/locuriDeConsum.service";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";

export default function Rapoarte() {
  const [selectedOption, setSelectedOption] = useState("RAPORT_SIMPLIFICAT");
  const [locuriConsum, setLocuriConsum] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [selectedLocDeConsum, setSelectedLocDeConsum] = useState("TOATE");

  const getLocuriDeConsum = async () => {
    setIsDataFetching(true);
    const response = await apiLocuriDeConsum.getLocuriDeConsum();
    if (response.isSuccess) {
      setLocuriConsum(response.response.data);
    }
    setIsDataFetching(false);
  };

  useEffect(() => {
    getLocuriDeConsum();
  }, []);

  const getCorrectTabRapoarte = (selectedOption) => {
    switch (selectedOption) {
      case "RAPORT_SIMPLIFICAT":
        return <TabRaportSimplificat />;
      case "RAPORT_DETALIAT":
        return <TabRaportDetaliat />;
      case "CONSUM_ORAR":
        return <div>consum orar</div>;
      default:
        return <TabRaportSimplificat />;
    }
  };

  return (
    <Layout>
      <SelectInput
        reactSelectClassName="bg-alb-green rapoarte_header-locDeConsum"
        isLabel={false}
        value={selectedLocDeConsum}
        onChange={(value) => {
          setSelectedLocDeConsum(value.value);
        }}
        placeholder="Loc de consum"
        options={[
          { value: "TOATE", label: "Toate locurile de consum" },
          ...locuriConsum.map((item) => ({
            value: item.metering_point_id,
            label: (
              <div>
                <img
                  src={item.utility_type === "Gaze" ? GAZ : CURENT}
                  height={"30px"}
                />
                {" " + item.address_summary}
              </div>
            ),
          })),
        ]}
      />
      <div className="rapoarte_header_row">
        <div className="rapoarte_header_selectors">
          <div
            onClick={() => {
              setSelectedOption("RAPORT_SIMPLIFICAT");
            }}
            className={`rapoarte_header_option ${
              selectedOption === "RAPORT_SIMPLIFICAT" &&
              "rapoarte_header_option-selected"
            }`}
          >
            <p>Raport simplificat</p>
          </div>
          <div
            onClick={() => {
              setSelectedOption("RAPORT_DETALIAT");
            }}
            className={`rapoarte_header_option ${
              selectedOption === "RAPORT_DETALIAT" &&
              "rapoarte_header_option-selected"
            }`}
          >
            <p>Raport detaliat</p>
          </div>
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    setSelectedOption("CONSUM_ORAR");*/}
          {/*  }}*/}
          {/*  className={`rapoarte_header_option ${*/}
          {/*    selectedOption === "CONSUM_ORAR" &&*/}
          {/*    "rapoarte_header_option-selected"*/}
          {/*  }`}*/}
          {/*>*/}
          {/*  <p>Consumuri orare</p>*/}
          {/*</div>*/}
        </div>
        <div className="autocitire-table-line" />
      </div>
      {getCorrectTabRapoarte(selectedOption)}
    </Layout>
  );
}
