import React from "react";
import { apiNomenclature } from "api/nomenclature.service";
import { toast } from "react-toastify";
import SelectInput from "components/SelectInput";
import SubmitButton from "components/SubmitButton";
import TextInput from "components/TextInput";

export default function TabAdresa({
  formik,
  setShowModificaDate,
  stateOfOptions,
  stateOfselectedKeyFromOptions,
}) {
  const onChangeCountry = async (item) => {
    formik.setFieldValue("tara", item.id);
    formik.setFieldValue("judet", "");
    formik.setFieldValue("oras", "");
    formik.setFieldValue("strada", "");
    formik.setFieldValue("cod_postal", "");

    stateOfOptions.setOptionCities([]);
    stateOfOptions.setOptionStreets([]);
    stateOfOptions.setOptionPostalCodes([]);
    stateOfselectedKeyFromOptions.setSelectedKeyFromOptionsCountry("id");
  };

  const onChangeCounty = async (item) => {
    formik.setFieldValue("judet", item.id);
    formik.setFieldValue("oras", "");
    formik.setFieldValue("strada", "");
    formik.setFieldValue("cod_postal", "");

    stateOfOptions.setOptionCities([]);
    stateOfOptions.setOptionStreets([]);
    stateOfOptions.setOptionPostalCodes([]);
    stateOfselectedKeyFromOptions.setSelectedKeyFromOptionsCounty("id");

    const response = await apiNomenclature.getCitiesByCounty(item.id);
    if (response.isSuccess) {
      stateOfOptions.setOptionCities(
        response.response.data.map((item) => ({
          value: item.city,
          label: item.city,
          id: item.city_id,
        }))
      );
    }
  };

  const onChangeCity = async (item) => {
    formik.setFieldValue("oras", item.id);
    formik.setFieldValue("strada", "");
    formik.setFieldValue("cod_postal", "");

    stateOfOptions.setOptionStreets([]);
    stateOfOptions.setOptionPostalCodes([]);
    stateOfselectedKeyFromOptions.setSelectedKeyFromOptionsCity("id");

    const response = await apiNomenclature.getStreetsByCity(item.id);
    if (response.isSuccess) {
      stateOfOptions.setOptionStreets(
        response.response.data.map((item) => ({
          value: item.street,
          label: item.street,
          id: item.street_id,
        }))
      );
    }
  };

  const onChangeStreet = async (item) => {
    formik.setFieldValue("strada", item.id);
    formik.setFieldValue("cod_postal", "");

    stateOfOptions.setOptionPostalCodes([]);
    stateOfselectedKeyFromOptions.setSelectedKeyFromOptionsStreet("id");

    const response = await apiNomenclature.getPostalCodesByStreet(item.id);
    if (response.isSuccess) {
      stateOfOptions.setOptionPostalCodes(
        response.response.data.map((item) => ({
          value: item.postal_code,
          label: item.postal_code,
          id: item.postal_code_id,
        }))
      );
    }
  };

  const onChangePostalCode = async (item) => {
    formik.setFieldValue("cod_postal", item.id);
    stateOfselectedKeyFromOptions.setSelectedKeyFromOptionsPostalCode("id");
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modifica-date-textInput-row">
        <SelectInput
          parrentClassName={"modifica-date-modal-select"}
          reactSelectClassName={"modifica-date-modal-react-select"}
          options={stateOfOptions.optionCountries}
          name={"tara"}
          id={"tara"}
          labelDescription={"Tara"}
          value={formik.values.tara}
          onChange={async (item) => await onChangeCountry(item)}
          selectedKeyFromOptions={
            stateOfselectedKeyFromOptions.selectedKeyFromOptionsCountry
          }
          placeholder="Selectează..."
          error={formik.errors.tara}
          touched={formik.touched.tara}
        />
      </div>
      <div className="modifica-date-textInput-row">
        <SelectInput
          parrentClassName={"modifica-date-modal-select"}
          reactSelectClassName={"modifica-date-modal-react-select"}
          options={stateOfOptions.optionCounties}
          name={"judet"}
          id={"judet"}
          labelDescription={"Judet"}
          value={formik.values.judet}
          onChange={async (item) => await onChangeCounty(item)}
          selectedKeyFromOptions={
            stateOfselectedKeyFromOptions.selectedKeyFromOptionsCounty
          }
          placeholder="Selectează..."
          error={formik.errors.judet}
          touched={formik.touched.judet}
        />
      </div>
      <div className="modifica-date-textInput-row">
        <SelectInput
          parrentClassName={"modifica-date-modal-select"}
          reactSelectClassName={"modifica-date-modal-react-select"}
          options={stateOfOptions.optionCities}
          name={"oras"}
          id={"oras"}
          labelDescription={"Oras"}
          value={formik.values.oras}
          onChange={async (item) => await onChangeCity(item)}
          selectedKeyFromOptions={
            stateOfselectedKeyFromOptions.selectedKeyFromOptionsCity
          }
          onMenuOpen={() => {
            if (!formik.values.judet)
              toast.error(
                "Selectează mai întăi județul pentru a putea selecta orașul!"
              );
          }}
          placeholder="Selectează..."
          error={formik.errors.oras}
          touched={formik.touched.oras}
        />
      </div>
      <div className="modifica-date-textInput-row">
        <SelectInput
          parrentClassName={"modifica-date-modal-select"}
          reactSelectClassName={"modifica-date-modal-react-select"}
          options={stateOfOptions.optionStreets}
          name={"strada"}
          id={"strada"}
          labelDescription={"Strada"}
          value={formik.values.strada}
          onChange={async (item) => await onChangeStreet(item)}
          selectedKeyFromOptions={
            stateOfselectedKeyFromOptions.selectedKeyFromOptionsStreet
          }
          onMenuOpen={() => {
            if (!formik.values.oras)
              toast.error(
                "Selectează mai întăi orasul pentru a putea selecta strada!"
              );
          }}
          placeholder="Selectează..."
          error={formik.errors.strada}
          touched={formik.touched.strada}
        />
      </div>
      <div className="modifica-date-textInput-row">
        <SelectInput
          parrentClassName={"modifica-date-modal-select"}
          reactSelectClassName={"modifica-date-modal-react-select"}
          options={stateOfOptions.optionPostalCodes}
          name={"cod_postal"}
          id={"cod_postal"}
          labelDescription={"Cod postal"}
          value={formik.values.cod_postal}
          onChange={async (item) => await onChangePostalCode(item)}
          selectedKeyFromOptions={
            stateOfselectedKeyFromOptions.selectedKeyFromOptionsPostalCode
          }
          onMenuOpen={() => {
            if (!formik.values.strada)
              toast.error(
                "Selectează mai întăi strada pentru a putea selecta codul poștal!"
              );
          }}
          placeholder="Selectează..."
          error={formik.errors.cod_postal}
          touched={formik.touched.cod_postal}
        />
      </div>
      <div className="modifica-date-textInput-row modifica-date-three-inputs">
        <div className="modifica-date-litte-row">
          <h2>Numar</h2>
          <TextInput
            id={"numar"}
            name={"numar"}
            type={"text"}
            placeholder={"Numar"}
            classNames={"modifica-date-modal-textInput"}
            value={formik.values.numar}
            error={formik.errors.numar}
            touched={formik.touched.numar}
            setFieldValue={formik.setFieldValue}
          />
        </div>
        <div className="modifica-date-litte-row">
          <h2>Bloc</h2>
          <TextInput
            id={"bloc"}
            name={"bloc"}
            type={"text"}
            placeholder={"Blocul"}
            classNames={"modifica-date-modal-textInput"}
            value={formik.values.bloc}
            error={formik.errors.bloc}
            touched={formik.touched.bloc}
            setFieldValue={formik.setFieldValue}
          />
        </div>
        <div className="modifica-date-litte-row">
          <h2>Scara</h2>
          <TextInput
            id={"scara"}
            name={"scara"}
            type={"text"}
            placeholder={"Scara"}
            classNames={"modifica-date-modal-textInput"}
            value={formik.values.scara}
            error={formik.errors.scara}
            touched={formik.touched.scara}
            setFieldValue={formik.setFieldValue}
          />
        </div>
      </div>

      <div className="modifica-date-textInput-row modifica-date-three-inputs">
        <div className="modifica-date-litte-row">
          <h2>Tronson</h2>
          <TextInput
            id={"tronson"}
            name={"tronson"}
            type={"text"}
            placeholder={"Tronsonul"}
            classNames={"modifica-date-modal-textInput"}
            value={formik.values.tronson}
            error={formik.errors.tronson}
            touched={formik.touched.tronson}
            setFieldValue={formik.setFieldValue}
          />
        </div>
        <div className="modifica-date-litte-row">
          <h2>Etaj</h2>
          <TextInput
            id={"etaj"}
            name={"etaj"}
            type={"text"}
            placeholder={"Etajul"}
            classNames={"modifica-date-modal-textInput"}
            value={formik.values.etaj}
            error={formik.errors.etaj}
            touched={formik.touched.etaj}
            setFieldValue={formik.setFieldValue}
          />
        </div>
        <div className="modifica-date-litte-row">
          <h2>Ap</h2>
          <TextInput
            id={"apartament"}
            name={"apartament"}
            type={"text"}
            placeholder={"Apartamentul"}
            classNames={"modifica-date-modal-textInput"}
            value={formik.values.apartament}
            error={formik.errors.apartament}
            touched={formik.touched.apartament}
            setFieldValue={formik.setFieldValue}
          />
        </div>
      </div>

      <div className="modifica-date-textInput-row modifica-date-one-inputs">
        <div className="modifica-date-litte-row">
          <h2>Sector</h2>
          <TextInput
            id={"sector"}
            name={"sector"}
            type={"text"}
            placeholder={"Sectorul"}
            classNames={"modifica-date-modal-textInput"}
            value={formik.values.sector}
            error={formik.errors.sector}
            touched={formik.touched.sector}
            setFieldValue={formik.setFieldValue}
          />
        </div>
      </div>
      <div className="modifica-date-textInput-row">
        <textarea
          id={"observatii"}
          name={"observatii"}
          type={"text"}
          placeholder={"Observatii"}
          className={"modifica-date-modal-textarea"}
          value={formik.values.observatii}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      <div className="modifica-date-modal-end-buttons">
        <SubmitButton
          onClick={() => setShowModificaDate(false)}
          classNames={"buton-bg-alb-green"}
          text={"Inchide"}
        />
        <SubmitButton typeOfButton={"submit"} text={"Salveaza"} />
      </div>
    </form>
  );
}
