import React from "react";
import { styleHelpers } from "helpers/styleHelpers";
import SubmitButton from "components/SubmitButton";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";

export default function AutocitireCard({ type, btnDisabled, onClickBtn }) {
  const onClick = () => {
    onClickBtn(type);
  };
  return (
    <div className="autocitire-card">
      <div className={styleHelpers.getAutocitireCardClass(type)}>
        <img src={type === "GAZ" ? GAZ : CURENT} alt="icon_gaz_curent" />
      </div>
      <div className="autocitire-card-content ">
        <h2 className="mb-2">
          Autocitire {type === "GAZ" ? "gaz" : "energie"}
        </h2>
        <SubmitButton
          disabled={btnDisabled}
          text="Trimite consum"
          classNames={"buton-bg-alb-green autocitire-card-button"}
          onClick={onClick}
        />
      </div>
    </div>
  );
}
