import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { tableTransmitereFacturaHelper } from "helpers/tableHelpers/tableTransmitereFacturaHelper";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import ComponentLoading from "components/Loaders/ComponentLoading";
import {
  createXLSX,
  saveAsExcelFile,
  getFormattedDateInterval,
} from "../../helpers/xlsx";

export default function DataTableTransmitereFactura() {
  const [transmitereFacturaTableData, setTrasmitereFacturaTableData] = useState(
    []
  );
  const [isDataFetching, setIsDataFetching] = useState(false);
  const dt = useRef(null);
  const contracteNumber = transmitereFacturaTableData.length;

  const getInvoiceSendingTypesCallback = async () => {
    setIsDataFetching(true);
    const response = await requestJson(
      "invoices/sending-type",
      RequestMethod.GET,
      { useAccessToken: true }
    );

    if (response.isSuccess) {
      setTrasmitereFacturaTableData(response.response.data);
      setIsDataFetching(false);
    }
  };

  const exportXLSX = () => {
    const COLUMNS_EXPORT_EXCEL = {
      UtilityType: "Furnizare",
      ContractNumber: "Detalii contract",
      InvoiceSendingType: "Transmitere factura",
    };

    const dataForXLSX = transmitereFacturaTableData.map((item) => ({
      [COLUMNS_EXPORT_EXCEL.UtilityType]:
        item?.utility_type === "gas" ? "Gaz" : "Electricitate",
      [COLUMNS_EXPORT_EXCEL.ContractNumber]: item?.contract_number || "",
      [COLUMNS_EXPORT_EXCEL.InvoiceSendingType]:
        item?.invoice_sending_type || "",
    }));

    const title = "Transmitere facturi - NOVA";
    const tableHead = [
      COLUMNS_EXPORT_EXCEL.UtilityType,
      COLUMNS_EXPORT_EXCEL.ContractNumber,
      COLUMNS_EXPORT_EXCEL.InvoiceSendingType,
    ];
    const excelBuffer = createXLSX(title, tableHead, dataForXLSX, 0);
    saveAsExcelFile(excelBuffer, title);
  };

  useEffect(() => {
    getInvoiceSendingTypesCallback();
  }, []);

  return (
    <>
      {isDataFetching ? (
        <ComponentLoading parrentClassName={"my-account-loading-div"} />
      ) : (
        <DataTable
          ref={dt}
          exportFilename="TransmitereFactura"
          className="transmitereFactura-table"
          value={transmitereFacturaTableData}
          header={tableTransmitereFacturaHelper.header(
            contracteNumber,
            exportXLSX
          )}
          paginator
          removableSort
          rows={4}
        >
          <Column
            sortable
            field="utility_type"
            header="Tip"
            body={tableTransmitereFacturaHelper.tableTipFurnizare}
          />
          <Column
            sortable
            field="contract_number"
            header="Detalii contract"
            body={tableTransmitereFacturaHelper.tableDetaliiContract}
          />
          <Column
            sortable
            field="invoice_sending_type"
            header="Transmitere factura"
            body={tableTransmitereFacturaHelper.tableTransmitereFactura}
          />
        </DataTable>
      )}
    </>
  );
}
