import React, { useContext } from "react";
import { AppContext } from "App";
import ComponentLoading from "components/Loaders/ComponentLoading";

export default function WelcomeContainer() {
  const { userLayoutInfo, isUserLayoutInfoFetching } = useContext(AppContext);

  return (
    <div className="welcome-container">
      <p>Bine ai venit</p>
      {isUserLayoutInfoFetching ? (
        <ComponentLoading />
      ) : (
        <>
          <p>{userLayoutInfo.data.account_name}</p>
          <p>COD Client: {userLayoutInfo.data.client_code}</p>
        </>
      )}
    </div>
  );
}
