import React from "react";
import Layout from "pages/Layout";
import ContulMeuDetails from "components/ContulMeu/ContulMeuDetails";
import PanouRecomandari from "components/ContulMeu/PanouRecomandari";
import Utilizatori from "components/ContulMeu/Utilizatori";
import ContracteleMele from "components/ContulMeu/ContracteleMele";

export default function ContulMeu() {
  return (
    <Layout>
      <div className="container-contul-meu">
        <div className="content-contul-meu-row">
          <ContulMeuDetails />
          {/*<PanouRecomandari />*/}
        </div>
        <ContracteleMele />
        <Utilizatori />
      </div>
    </Layout>
  );
}
