import { requestJson, RequestMethod } from "helpers/httpHelper";

const getCountries = async () => {
  const response = await requestJson(
    "nomenclature/countries",
    RequestMethod.GET,
    {
      useAccessToken: true
    }
  );
  return response;
};

const getCounties = async () => {
  const response = await requestJson(
    "nomenclature/counties",
    RequestMethod.GET,
    {
      useAccessToken: true
    }
  );
  return response;
};

const getCities = async () => {
  const response = await requestJson("nomenclature/cities", RequestMethod.GET, {
    useAccessToken: true
  });
  return response;
};

const getStreets = async () => {
  const response = await requestJson(
    "nomenclature/streets",
    RequestMethod.GET,
    {
      useAccessToken: true
    }
  );
  return response;
};

const getPostalCodes = async () => {
  const response = await requestJson(
    "nomenclature/postal-codes",
    RequestMethod.GET,
    {
      useAccessToken: true
    }
  );
  return response;
};

const getTipFurnizare = async () => {
  const response = await requestJson(
    "nomenclature/utility-types",
    RequestMethod.GET,
    {
      useAccessToken: true
    }
  );
  return response;
};

const getSubiect = async () => {
  const response = await requestJson(
    "nomenclature/subjects",
    RequestMethod.GET,
    {
      useAccessToken: true
    }
  );
  return response;
};

const getCitiesByCounty = async (countyId) => {
  const response = await requestJson(
    "nomenclature/cities-by-county",
    RequestMethod.GET,
    {
      useAccessToken: true,
      queryStringParams: { county_id: countyId },
    }
  );
  return response;
};

const getStreetsByCity = async (cityId) => {
  const response = await requestJson(
    "nomenclature/streets-by-city",
    RequestMethod.GET,
    {
      useAccessToken: true,
      queryStringParams: { city_id: cityId },
    }
  );
  return response;
};

const getPostalCodesByStreet = async (streetId) => {
  const response = await requestJson(
    "nomenclature/postal-codes-by-street",
    RequestMethod.GET,
    {
      useAccessToken: true,
      queryStringParams: { street_id: streetId },
    }
  );
  return response;
};

export const apiNomenclature = {
  getCountries,
  getCounties,
  getCities,
  getStreets,
  getPostalCodes,
  getTipFurnizare,
  getSubiect,
  getCitiesByCounty,
  getStreetsByCity,
  getPostalCodesByStreet,
};
