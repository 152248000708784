import axios from "axios";
import { API_URL } from "constants/api.constants";
import { toast } from "react-toastify";
import authManager from "./authManager";

export const RequestMethod = {
  GET: "Get",
  POST: "Post",
  PUT: "Put",
  DELETE: "Delete",
  PATCH: "Patch",
};

export async function requestJson(path, method, options, responseType) {
  try {
    const response = await executeRequest(
      API_URL,
      path,
      method,
      options,
      responseType
    );

    console.log("🚀 ~ requestJson ~ response:", response);
    return {
      response: response,
      isSuccess: true,
      error: response?.data?.error || null,
    };
  } catch (ex) {
    console.log("🚀 ~ requestJson ~ ex:", ex);

    if (ex?.response?.status === 401 && path !== "user/login") {
      toast.error("Eroare de autentificare token ");
      localStorage.clear();
      window.location.reload();
    }

    return {
      response: null,
      isSuccess: false,
      error: ex?.response?.data?.error || ex?.message || ex,
    };
  }
}

async function executeRequest(
  baseUrl,
  path,
  method,
  options,
  responseType = "json"
) {
  const url = baseUrl + path;
  const headers = await getHeaders(options);

  let response;
  switch (method) {
    case RequestMethod.GET:
      response = await axios.get(jsonToUrl(url, options?.queryStringParams), {
        headers,
        responseType,
      });

      break;
    case RequestMethod.POST:
      response = await axios.post(
        jsonToUrl(url, options?.queryStringParams),
        options?.data,
        {
          headers: headers,
        }
      );
      break;
    case RequestMethod.PUT:
      response = await axios.put(
        jsonToUrl(url, options?.queryStringParams),
        options?.data,
        {
          headers: headers,
        }
      );
      break;
    case RequestMethod.DELETE:
      response = await axios.delete(
        jsonToUrl(url, options?.queryStringParams),
        { headers: headers, data: options?.data }
      );
      break;
    case RequestMethod.PATCH:
      response = await axios.patch(
        jsonToUrl(url, options?.queryStringParams),
        options?.data,
        {
          headers: headers,
        }
      );
      break;
    default:
      break;
  }
  return response?.data;
}

const getHeaders = async (options) => {
  let headers = {};

  if (options?.useAccessToken) {
    const userToken = await authManager.getAccessTokenSilently();
    headers["Authorization"] = `Bearer ${userToken}`;
  }

  if (options?.headers) {
    options.headers.forEach((header) => {
      headers[header.name] = header.value;
    });
  }

  return headers;
};

function jsonToUrl(url, data) {
  if (data) {
    const urlData = Object.keys(data)
      .map((k) => {
        if (data[k] === undefined) return;
        if (Array.isArray(data[k])) {
          const result = data[k]
            .map((x) => encodeURIComponent(k) + "=" + encodeURIComponent(x))
            .join("&");
          return result;
        }
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
      })
      .join("&");

    if (urlData === undefined && urlData === "") return url;
    return `${url}?${urlData}`;
  }

  return url;
}
