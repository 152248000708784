import React from "react";
import TextInput from "components/TextInput";
import SubmitButton from "components/SubmitButton";

export default function TabDatePersonale({ formik, setShowModificaDate }) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modifica-date-textInput-row">
        <h2>Nume</h2>
        <TextInput
          id={"nume"}
          name={"nume"}
          type={"text"}
          placeholder={"Numele"}
          classNames={"modifica-date-modal-textInput"}
          value={formik.values.nume}
          error={formik.errors.nume}
          touched={formik.touched.nume}
          setFieldValue={formik.setFieldValue}
        />
      </div>
      <div className="modifica-date-textInput-row">
        <h2>CNP/CUI Partener</h2>
        <TextInput
          id={"cnp_cui_partener"}
          name={"cnp_cui_partener"}
          type={"text"}
          placeholder={"CNP/CUI Partener"}
          classNames={"modifica-date-modal-textInput"}
          value={formik.values.cnp_cui_partener}
          error={formik.errors.cnp_cui_partener}
          touched={formik.touched.cnp_cui_partener}
          setFieldValue={formik.setFieldValue}
        />
      </div>
      <div className="modifica-date-textInput-row">
        <h2>CNP/CUI Utilizator</h2>
        <TextInput
          id={"cnp_cui_utilizator"}
          name={"cnp_cui_utilizator"}
          type={"text"}
          placeholder={"CNP/CUI Utilizator"}
          classNames={"modifica-date-modal-textInput"}
          value={formik.values.cnp_cui_utilizator}
          error={formik.errors.cnp_cui_utilizator}
          touched={formik.touched.cnp_cui_utilizator}
          setFieldValue={formik.setFieldValue}
        />
      </div>
      <div className="modifica-date-textInput-row">
        <h2>EMAIL</h2>
        <TextInput
          id={"email"}
          name={"email"}
          type={"text"}
          placeholder={"Email"}
          classNames={"modifica-date-modal-textInput"}
          value={formik.values.email}
          error={formik.errors.email}
          touched={formik.touched.email}
          setFieldValue={formik.setFieldValue}
        />
      </div>
      <div className="modifica-date-textInput-row">
        <h2>TELEFON</h2>
        <TextInput
          id={"telefon"}
          name={"telefon"}
          type={"text"}
          placeholder={"Telefon"}
          classNames={"modifica-date-modal-textInput"}
          value={formik.values.telefon}
          error={formik.errors.telefon}
          touched={formik.touched.telefon}
          setFieldValue={formik.setFieldValue}
        />
      </div>
      <div className="modifica-date-modal-end-buttons">
        <SubmitButton
          onClick={() => setShowModificaDate(false)}
          classNames={"buton-bg-alb-green"}
          text={"Inchide"}
        />
        <SubmitButton typeOfButton={"submit"} text={"Salveaza"} />
      </div>
    </form>
  );
}
