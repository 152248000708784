import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "App";
import { useFormik } from "formik";
import { resetPasswordSchema } from "schemas/yupSchema";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "api/auth.service";
import { storageHelpers } from "helpers/storageHelpers";
import { toast } from "react-toastify";
import TextInput from "components/TextInput";
import SubmitButton from "components/SubmitButton";
import DOWN_BACKGROUND from "assets/images/login/login-down-background.png";
import BACKGROUND from "assets/images/login/background.png";
import LOGO from "assets/images/login/logo.png";
import BACKGROUND_MOBILE_TOP from "assets/images/login/background_mobile_top.png";
import BACKGROUND_MOBILE_BOTTOM from "assets/images/login/background_mobile_bottom.png";

export default function ResetPassword() {
  const { setIsScreenLoading } = useContext(AppContext);
  const navigation = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: isFormSubmitted,
    initialValues: {
      password: "",
      passwordRetype: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    const urlParams = new URLSearchParams(window.location.search);

    setIsScreenLoading(true);
    const response = await AuthService.resetPassword(
      urlParams.get("token"),
      urlParams.get("userid"),
      urlParams.get("email"),
      values.password,
      values.passwordRetype
    );

    if (response.isSuccess) {
      navigation("/");
      toast.success("Parola a fost setata cu succes!");
    } else {
      toast.error("A aparut o eroare. Va rugam sa incercati din nou.");
    }

    setIsScreenLoading(false);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/" && storageHelpers.isUserLoggedIn()) {
      navigation("/dashboard");
      toast.success("Esti logat!");
    }
  }, []);

  return (
    <div className="login-page">
      <div className="login-main-container">
        <img src={LOGO} className="login-logo" alt="logo" />
        <h1>Resetare parola</h1>
        <form className="login-form" onSubmit={formik.handleSubmit}>
          <div className="login-input-row">
            <TextInput
              id={"password"}
              name={"password"}
              type={"password"}
              placeholder={"Introduceti parola noua"}
              classNames={"login-textInput"}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              setFieldValue={formik.setFieldValue}
            />
          </div>

          <div className="login-input-row">
            <TextInput
              id={"passwordRetype"}
              name={"passwordRetype"}
              type={"password"}
              placeholder={"Confirmati parola noua"}
              classNames={"login-textInput"}
              value={formik.values.passwordRetype}
              error={formik.errors.passwordRetype}
              touched={formik.touched.passwordRetype}
              setFieldValue={formik.setFieldValue}
            />
          </div>
          <a onClick={() => navigation("/")}>Inapoi la autentificare</a>
          <SubmitButton
            onClick={() => setIsFormSubmitted(true)}
            typeOfButton={"submit"}
            text={"Trimite"}
            classNames={"login-button"}
          />
        </form>
      </div>
      <img
        src={DOWN_BACKGROUND}
        className="login-down-background"
        alt="down-background"
      />
      <img src={BACKGROUND} className="login-background" alt="background" />
      <img
        src={BACKGROUND_MOBILE_TOP}
        className="login-background-mobile-top"
        alt="background-mobile-top"
      />
      <img
        src={BACKGROUND_MOBILE_BOTTOM}
        className="login-background-mobile-bottom"
        alt="background-mobile-bottom"
      />
    </div>
  );
}
