import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { apiAutocitire } from "api/autocitire.service";
import { tableAutocitireHelper } from "helpers/tableHelpers/tableAutocitireHelper";
import ComponentLoading from "components/Loaders/ComponentLoading";
import ModalAdaugaAutocitire from "components/Autocitire/ModalAdaugaAutocitire";
import {
  createXLSX,
  saveAsExcelFile,
  getFormattedDateInterval,
} from "../../helpers/xlsx";
import { calculatePaginationArray } from "../../helpers/helpers";
export default function DataTableAutocitire({
  isSelfReadingsEnabled,
  showAdaugaAutocitire,
  setShowAdaugaAutocitire,
  selectedOption,
  setSelectedOption,
}) {
  console.log("🚀 ~ isSelfReadingsEnabled:", isSelfReadingsEnabled);
  const [autocitireTableData, setAutocitireTableData] = useState([]);
  const [filteredAutocitireTableData, setFilteredAutocitireTableData] =
    useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const autocitiriNumber = filteredAutocitireTableData.length;

  const [fetchAgainAutocitiri, setFetchAgainAutocitiri] = useState(0);
  const dt = useRef(null);

  const exportXLSX = () => {
    const COLUMNS_EXPORT_EXCEL = {
      UtilityType: "Furnizare",
      Date: "Data",
      Location: "Adresa de furnizare",
      TotalConsumption: "Consum total",
      MetersConsumptionNo: "Autocitiri trimise",
      MetersSeries: "Serie contor",
      Status: "Status autocitire",
    };
    const dataForXLSX = filteredAutocitireTableData
      .map((item) => ({
        [COLUMNS_EXPORT_EXCEL.UtilityType]: item?.utility_type || "",
        [COLUMNS_EXPORT_EXCEL.Date]: item?.date || "",
        [COLUMNS_EXPORT_EXCEL.Location]: item?.metering_point_address || "",
        [COLUMNS_EXPORT_EXCEL.TotalConsumption]: `${
          item?.total_consumption || ""
        } ${item?.utility_type === "Gaz" ? "m3" : "kWh"}`,
        [COLUMNS_EXPORT_EXCEL.MetersConsumptionNo]: `${
          item?.meters_no || ""
        } din ${item?.meters_consumption_no || ""} contoare`,
        [COLUMNS_EXPORT_EXCEL.Status]:
          item?.status === "locked" ? "Blocat" : "Deblocat",
      }))
      .reverse();
    const intervalAutocitiri = getFormattedDateInterval(
      dataForXLSX,
      "Data",
      true
    );
    const title = `Autocitiri NOVA (${intervalAutocitiri})`;

    const tableHead = Object.keys(COLUMNS_EXPORT_EXCEL);

    const excelBuffer = createXLSX(title, tableHead, dataForXLSX, 2);
    saveAsExcelFile(excelBuffer, title);
  };

  const getAutocitireTableData = async () => {
    setIsDataFetching(true);
    const response = await apiAutocitire.getAutocitireTableData();
    if (response.isSuccess) {
      console.log("🚀 ~ getAutocitireTableData ~ response.response.data:", response.response.data);

      setAutocitireTableData(response.response.data);
      setFilteredAutocitireTableData(
        response.response.data.map((item) => {
          const status = isSelfReadingsEnabled.enabled ? "unlocked" : "locked";
          return {
            ...item,
            status,
          };
        })
      );
      setIsDataFetching(false);
    }
    };

  useEffect(() => {
    getAutocitireTableData();
  }, [fetchAgainAutocitiri]);

  const rowsPerPageOptions = calculatePaginationArray(
    filteredAutocitireTableData
  );
  if (showAdaugaAutocitire) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  return (
    <>
      {isDataFetching ? (
        <ComponentLoading />
      ) : (
        <>
          <DataTable
            ref={dt}
            exportFilename="Autocitiri"
            className="autocitire-table"
            value={filteredAutocitireTableData}
            header={tableAutocitireHelper.header(
              selectedOption,
              setSelectedOption,
              autocitireTableData,
              setFilteredAutocitireTableData,
              autocitiriNumber,
              setShowAdaugaAutocitire,
              exportXLSX,
              isSelfReadingsEnabled.enabled
            )}
            removableSort
            paginator
            rows={5}
            rowsPerPageOptions={rowsPerPageOptions}
          >
            <Column
              sortable
              field="utility_type"
              header="Tip"
              body={tableAutocitireHelper.tableTip}
            />
            <Column
              sortable
              field="date"
              header="Data"
              body={tableAutocitireHelper.tableData}
            />
            <Column
              sortable
              field="metering_point_address"
              header="Loc de consum"
              body={tableAutocitireHelper.tableLocDeConsum}
            />
            <Column
              sortable
              field="total_consumption"
              header="Consum total"
              body={tableAutocitireHelper.tableConsumTotal}
            />
            <Column
              sortable
              field="self_readings_total_no"
              header="Autocitiri trimise"
              body={tableAutocitireHelper.tableAutocitiriTrimise}
            />
            <Column
              sortable
              field="meters_series"
              header="Serie contor"
              body={tableAutocitireHelper.tableSerieContor}
            />
            <Column
              sortable
              field="status"
              header="Status"
              body={tableAutocitireHelper.tableStatus}
            />
          </DataTable>

          {showAdaugaAutocitire && (
            <ModalAdaugaAutocitire
              setShowAdaugaAutocitire={setShowAdaugaAutocitire}
              setFetchAgainAutocitiri={setFetchAgainAutocitiri}
              selectedFilter={selectedOption}
            />
          )}
        </>
      )}
    </>
  );
}
