import React from "react";
import ReactECharts from "echarts-for-react";
import SelectInput from "components/SelectInput";
import graphData from "data/graphData";
import objectSchema from "schemas/objectSchema";

export default function TabRaportDetaliat() {
  return (
    <>
      <div className="rapoarte-grafic-detaliat-mare">
        <div className="rapoarte-grafic-detaliat-mare-header">
          <h2>Prezentare</h2>
          <p>Sume facturate lunar 2023 comparat cu 2022</p>
        </div>
        <ReactECharts
          option={graphData.optionsRDPrezentare}
          className="rapoarte-grafic-detaliat-prezentare"
        />
      </div>

      <div className="rapoarte-grafic-detaliat-dublu">
        <h2>Energie electrica</h2>
        <div className="rapoarte-grafic-detaliat-dublu-row">
          <div className="rapoarte-grafic-detaliat-solo">
            <h2>Consum mediu zilnic pe luna aferent 2023 comparat cu 2022</h2>
            <ReactECharts
              option={graphData.optionsRDEnergieElectricaUNU}
              className="rapoarte-grafic-detaliat-solo-chart"
            />
          </div>
          <div className="rapoarte-grafic-detaliat-solo">
            <h2>Consum mediu zilnic pe luna aferent 2023 comparat cu 2022</h2>
            <ReactECharts
              option={graphData.optionsRDEnergieElectricaDOI}
              className="rapoarte-grafic-detaliat-solo-chart"
            />
          </div>
        </div>
      </div>

      <div className="rapoarte-grafic-detaliat-dublu">
        <h2>Gaze naturale</h2>
        <div className="rapoarte-grafic-detaliat-dublu-row">
          <div className="rapoarte-grafic-detaliat-solo">
            <h2>Consum mediu zilnic pe luna aferent 2023 comparat cu 2022</h2>
            <ReactECharts
              option={graphData.optionsRDGazeNaturaleUNU}
              className="rapoarte-grafic-detaliat-solo-chart"
            />
          </div>
          <div className="rapoarte-grafic-detaliat-solo">
            <h2>Consum mediu zilnic pe luna aferent 2023 comparat cu 2022</h2>
            <ReactECharts
              option={graphData.optionsRDGazeNaturaleDOI}
              className="rapoarte-grafic-detaliat-solo-chart"
            />
          </div>
        </div>
      </div>

      <div className="rapoarte-grafic-detaliat-mare-curbaConsum">
        <div className="rapoarte-grafic-detaliat-mare-header-curbaConsum">
          <h2>Curba de consum</h2>
          <div className="rapoarte-grafic-detaliat-mare-header-curbaConsum-row">
            <SelectInput
              reactSelectClassName="rapoarte-grafic-detaliat-mare-header-curbaConsum-deLaLuna"
              isLabel={false}
              // value={"ok"}
              onChange={(value) => {
                console.log(value.value);
              }}
              placeholder="De la luna"
              options={objectSchema.optionsTipFurnizare}
            />
            <SelectInput
              reactSelectClassName="rapoarte-grafic-detaliat-mare-header-curbaConsum-panaLaLuna"
              isLabel={false}
              // value={"ok"}
              onChange={(value) => {
                console.log(value.value);
              }}
              placeholder="Pana la luna"
              options={objectSchema.optionsTipFurnizare}
            />
            <SelectInput
              reactSelectClassName="rapoarte-grafic-detaliat-mare-header-curbaConsum-optiuneAfisare"
              isLabel={false}
              // value={"ok"}
              onChange={(value) => {
                console.log(value.value);
              }}
              placeholder="Optiune afisare"
              options={objectSchema.optionsTipFurnizare}
            />
          </div>
        </div>
        <ReactECharts
          option={graphData.optionsRDCurbaConsum}
          className="rapoarte-grafic-detaliat-curbaConsum"
        />
      </div>
    </>
  );
}
