import { toast } from "react-toastify";
import { storageHelpers } from "helpers/storageHelpers";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import jwt_decode from "jwt-decode";

class AuthManager {
    constructor() {
      this.lock = false;
    }
  
    async getAccessTokenSilently() {
      let cachedAccessToken = this.getCachedToken();
      if(cachedAccessToken === null) {
        return null; // SHOULD REDIRECT TO LOGIN PAGE
      }
      else if(cachedAccessToken.isActive === true) {
        return cachedAccessToken.accessToken;
      }

      while (this.lock) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
  
      this.lock = true;
  
      try {
        cachedAccessToken = this.getCachedToken() ?? cachedAccessToken;
        if(cachedAccessToken.isActive === true){
            return cachedAccessToken?.accessToken;
          }
  
        const refreshedToken = await this.refreshToken(cachedAccessToken.accessToken);
  
        storageHelpers.setUserToken(refreshedToken);
        return refreshedToken;
      } finally {
        this.lock = false;
      }
    }

    getCachedToken() {
        const accessToken = storageHelpers.getUserToken();
        if(!accessToken) {
          return null;
        }
  
        return { accessToken: accessToken, isActive: this.isAccessTokenActive(accessToken) }
    }

    isAccessTokenActive (accessToken)  {
        const jwtObj = jwt_decode(accessToken);
        const tokenExpirationDate = new Date(jwtObj.exp * 1000);
        const currentDate = new Date();
        currentDate.setSeconds(currentDate.getSeconds() + 10);

        return tokenExpirationDate > currentDate;
      };

    async refreshToken(token) {
        const response = await requestJson("user/refresh", RequestMethod.GET, {
            headers: [{ name: "Token", value: token }],
          });

          if (!response.isSuccess) {
            toast.error("A aparut o eroare! Va rugam sa incercati din nou!");
            return;
          }
          return response.response.token;
      }
    }


    const authManager = new AuthManager();
    export default authManager;