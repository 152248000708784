import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styleHelpers } from "helpers/styleHelpers";
import { ReactComponent as ICON_ACASA } from "assets/images/menu/icon_acasa.svg";
import { ReactComponent as ICON_FACTURI } from "assets/images/menu/icon_facturi.svg";
import { ReactComponent as ICON_AUTOCITIRE } from "assets/images/menu/icon_autocitire.svg";
import { ReactComponent as ICON_LOCURI } from "assets/images/menu/icon_locuri.svg";
import { ReactComponent as ICON_RAPOARTE } from "assets/images/menu/icon_rapoarte.svg";

export default function Footer() {
  const navigation = useNavigate();
  const location = useLocation();

  return (
    <div className="footer">
      <div
        onClick={() => navigation("/dashboard")}
        className={styleHelpers.getFooterDivClass(location, "/dashboard")}
      >
        <ICON_ACASA />
      </div>
      <div
        onClick={() => navigation("/facturi-si-plati")}
        className={styleHelpers.getFooterDivClass(
          location,
          "/facturi-si-plati"
        )}
      >
        <ICON_FACTURI />
      </div>
      <div
        onClick={() => navigation("/autocitire")}
        className={styleHelpers.getFooterDivClass(location, "/autocitire")}
      >
        <ICON_AUTOCITIRE />
      </div>
      <div
        onClick={() => navigation("/locuri-de-consum")}
        className={styleHelpers.getFooterDivClass(
          location,
          "/locuri-de-consum"
        )}
      >
        <ICON_LOCURI />
      </div>
      <div
        onClick={() => navigation("/rapoarte")}
        className={styleHelpers.getFooterDivClass(location, "/rapoarte")}
      >
        <ICON_RAPOARTE />
      </div>
    </div>
  );
}
