import React from "react";
import ReactECharts from "echarts-for-react";
import ComponentLoading from "components/Loaders/ComponentLoading";

import * as echarts from "echarts";
import { orderBy } from "lodash";
export default function DashboardGrafic({
  isDataFetching = false,
  facturileMeleTableData = [],
}) {
  function formatDateForComparison(date) {
    const year = date.getFullYear();
    const monthNames = [
      "Ian",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Iun",
      "Iul",
      "Aug",
      "Sep",
      "Oct",
      "Noi",
      "Dec",
    ];
    const month = date.getMonth();
    return `${monthNames[month]} ${year}`;
  }

  function extractUniqueYearsAndMonths(array) {
    const uniqueYearMonths = new Set();
    array.forEach((obj) => {
      if (!obj.invoice_due_date) return;
      const currentDate = new Date(obj.invoice_due_date);
      const formattedDate = formatDateForComparison(currentDate);

      // Check if the formatted date is already in the set
      if (!uniqueYearMonths.has(formattedDate)) {
        uniqueYearMonths.add(formattedDate);
      }
    });

    return Array.from(uniqueYearMonths);
  }

  const getChartValues = (invoices) => {
    let result = {};
    if (invoices.length > 0) {
      function orderByDate(array) {
        return orderBy(
          array,
          [(obj) => new Date(obj.invoice_due_date)],
          ["asc"]
        );
      }
      function getAmountsByMonth(objectsList) {
        const monthlyAmounts = {};

        objectsList.forEach((obj) => {
          const date = new Date(obj.invoice_due_date);

          const formattedDate = formatDateForComparison(date);
          const amount = parseFloat(obj.amount);

          if (!monthlyAmounts[formattedDate]) {
            monthlyAmounts[formattedDate] = 0; // Initialize if not exist
          }
          monthlyAmounts[formattedDate] += amount;
        });

        const monthlyAmountsArray = Object.values(monthlyAmounts).map(
          (amount) => (Number.isInteger(amount) ? amount : amount.toFixed(2))
        );

        return monthlyAmountsArray;
      }

      const months = extractUniqueYearsAndMonths(
        orderByDate(invoices)
      );

      const electricityInvoices = invoices.filter(
        (item) => item.utility_type !== "gas"
      );

      const gasInvoices = invoices.filter(
        (item) => item.utility_type === "gas"
      );

      const chartValuesForElectricity = getAmountsByMonth(electricityInvoices);
      const chartValuesForGas = getAmountsByMonth(gasInvoices);
      console.log(
        "🚀 ~ getChartValues ~ chartValuesForGas:",
        chartValuesForGas
      );

      const options = {
        color: ["#52C2B5"],
        grid: { left: 50, right: 35 },
        legend: {
          orient: "horizontal",
          top: "0%",
          left: "5%",
          icon: "circle",
        },
        xAxis: {
          type: "category",
          data: months,
          boundaryGap: false, // This helps in aligning the first and last labels closer to the edges
        },
        yAxis: {
          type: "value",
          boundaryGap: ["0%", "0%"],
        },
        series: [
          {
            data: chartValuesForElectricity,
            type: "line",
            smooth: true,
            showSymbol: false,
            color: "#515FAB", // Green color for Series 2

            name: "Electricitate",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#515FAB" },
                { offset: 1, color: "rgba(82, 194, 181, 0)" },
              ]),
            },
          },
          {
            color: "#eaa641", // Red color for Series 1
            data: chartValuesForGas,
            type: "line",
            smooth: true,
            name: "Gaz",
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#eaa641" },
                { offset: 1, color: "rgba(20, 40, 100, 0)" },
              ]),
            },
          },
        ],
        tooltip: {
          trigger: "axis",
        },
      };

      result = options;
    }
    return result;
  };

  const chartValues = getChartValues(facturileMeleTableData);
  if (isDataFetching) {
    return <ComponentLoading parrentClassName={"my-account-loading-div"} />;
  } else {
    return (
      <div className="grafic-facturi">
        <div className="grafic-facturi-content">
          <h2>Grafic facturi</h2>
          <div className="grafic-facturi-selectors"></div>
        </div>
        <ReactECharts option={chartValues} />
      </div>
    );
  }
}
