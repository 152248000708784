import * as yup from "yup";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};:'",.<>/?\\|]).{6,}$/;


export const loginSchema = yup.object().shape({
   email: yup.string().email("Email-ul nu este valid").required("Email-ul este obligatoriu"),
   password: yup.string().min(6, "Parola trebuie sa aiba minim 6 caractere").required("Parola este obligatorie"),
});

export const resetPasswordSchema = yup.object().shape({
   password: yup
       .string()
       .matches(passwordRegex, "Parola trebuie sa contina minim 6 caractere, o litera mare, o cifra si un caracter special")
       .required("Parola noua este obligatorie"),

   passwordRetype: yup
       .string()
       .oneOf([yup.ref("password"), null], "Trebuie sa fie identica cu parola noua")
       .matches(passwordRegex, "Parola trebuie sa contina minim 6 caractere, o litera mare, o cifra si un caracter special")
       .required("Confirmarea parolei este obligatorie"),
});

export const recoverPasswordSchema = yup.object().shape({
   email: yup.string().email("Email-ul nu este valid").required("Email-ul este obligatoriu"),
});


export const deschideTicketSchema = yup.object().shape({
   tipTichet: yup.string().required("Tipul tichetului este obligatoriu"),
   tipFurnizare: yup.string().required("Tipul furnizarii este obligatoriu"),
   subiect: yup.string().required("Subiectul este obligatoriu"),
   locDeConsum: yup.string().required("Locul de consum este obligatoriu"),
   dataProblemei: yup.date().required("Data problemei este obligatorie"),
});

export const schimbaParolaSchema = yup.object().shape({
   currentPassword: yup.string().min(6, "Parola trebuie sa aiba minim 6 caractere").required("Parola este obligatorie"),
   newPassword: yup
       .string()
       .matches(passwordRegex, "Parola trebuie sa contina minim 6 caractere, o litera mare, o cifra si un caracter special")
       .required("Parola noua este obligatorie"),

   confirmNewPassword: yup
       .string()
       .oneOf([yup.ref("newPassword"), null], "Trebuie sa fie identica cu parola noua")
       .matches(passwordRegex, "Parola trebuie sa contina minim 6 caractere, o litera mare, o cifra si un caracter special")
       .required("Confirmarea parolei este obligatorie"),
});

export const modalUtilizatoriSchema = yup.object().shape({
   prenume: yup.string().required("Prenumele este obligatoriu"),
   nume: yup.string().required("Numele este obligatoriu"),
   email: yup.string().email("Email-ul nu este valid").required("Email-ul este obligatoriu"),
   telefon: yup.string().required("Telefonul este obligatoriu"),
});

export const addNewAutocitireSchema = yup.object().shape({
   utility_type: yup.string().required("Tipul furnizarii este obligatoriu"),
   metering_point_id: yup.string().required("Locul de consum este obligatoriu"),
   date: yup.string().required("Data autocitirii este obligatorie"),
   total_consumption: yup.string().required("Consumul total este obligatoriu"),
});
