import React, { useContext } from "react";
import { AppContext } from "App";
import Layout from "pages/Layout";
import LocDeConsumInfoContent from "components/LocDeConsum/LocDeConsumInfoContent";
import DataTableLocDeConsum from "components/LocDeConsum/DataTableLocDeConsum";

export default function LocuriDeConsum() {
  const { locDeConsumShowing, setLocDeConsumShowing } = useContext(AppContext);
  return (
    <Layout>
      {!locDeConsumShowing.isShowing ? (
        <DataTableLocDeConsum setLocDeConsumShowing={setLocDeConsumShowing} />
      ) : (
        <LocDeConsumInfoContent locDeConsumShowing={locDeConsumShowing} />
      )}
    </Layout>
  );
}
