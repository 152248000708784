import React, { useState } from "react";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";
import { Dialog } from "primereact/dialog";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { isString } from "lodash";

export default function ModalDetaliiFactura({
  setIsDialogVisible,
  isDialogVisible,
  selectedInvoice,
}) {
  const [isDownloadingFile, setIsDownloadingFile] = useState({
    invoice: false,
    pv: false,
  });

  const showErrorAtDownload = () => {
    toast.error(
      "Documentul solicitat nu este disponibil momentan. Vă rugăm să reîncărcați mai târziu."
    );
  };

  const onClickDownload = async (e, isPv = false) => {
    if (!isString(selectedInvoice?.invoice_id)) {
      showErrorAtDownload();
      return;
    }

    setIsDownloadingFile({
      ...isDownloadingFile,
      [isPv ? "pv" : "invoice"]: true,
    });

    const documentType = isPv ? "pv" : "invoice";

    const response = await requestJson(
      `invoices/file/${documentType}/${selectedInvoice?.invoice_id}`,
      RequestMethod.GET,
      { useAccessToken: true },
      "blob"
    );

    const documentName = selectedInvoice?.invoice_date || "";

    if (response.isSuccess && response?.response) {
      const fileName = isPv
        ? `proces-verbal-nova-${documentName}`
        : `factura-nova-${documentName}`;
      try {
        saveAs(response?.response, fileName);
      } catch (err) {
        showErrorAtDownload();
      }
    } else {
      showErrorAtDownload();
    }
    setIsDownloadingFile({
      ...isDownloadingFile,
      [isPv ? "pv" : "invoice"]: false,
    });
  };

  const header = () => {
    return (
      <>
        <img
          src={selectedInvoice.utility_type === "gas" ? GAZ : CURENT}
          height={40}
          style={{ marginRight: "15px" }}
          alt="icon_gaz_curent"
        />
        {selectedInvoice.utility_type === "gas"
          ? "Detalii Factură  Gaz"
          : " Detalii Factură Electricitate"}
      </>
    );
  };

  return (
    <Dialog
      header={header}
      className={"facturiMele-dialog"}
      visible={isDialogVisible}
      onHide={() => setIsDialogVisible(false)}
      style={{ width: "75vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <div className="content">
        <div>
          <p>
            <span>ID Factură:</span> {selectedInvoice.invoice_id}
          </p>
          <div>
            <span>Locuri de consum:</span>
            {selectedInvoice.invoice_metering_points.map((item, index) => {
              return (
                <div key={index}>
                  <p>
                    {index + 1}: {item.metering_point_address} -{" "}
                    <span>Suma de plata:</span> {item.metering_point_value}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="second">
            <p>
              <span>Data emitere:</span> {selectedInvoice.invoice_date}
            </p>
            <p>
              <span>Data scadentă:</span> {selectedInvoice.invoice_due_date}
            </p>
            <p>
              <span>Total factura:</span> {selectedInvoice.amount}
            </p>

            <button
              type=""
              onClick={onClickDownload}
              className="custom-submitButton dashboard_table_status_buton"
            >
              {isDownloadingFile.invoice ? "Se încarca..." : "Descarcă factura"}
            </button>
            <button
              type=""
              onClick={(e) => onClickDownload(e, true)}
              className="custom-submitButton dashboard_table_status_buton ml-2"
            >
              {isDownloadingFile.pv
                ? "Se încarcă..."
                : "Descarcă proces verbal"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
