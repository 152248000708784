import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { tableIstoricPlatiHelper } from "helpers/tableHelpers/tableIstoricPlatiHelper";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import ComponentLoading from "components/Loaders/ComponentLoading";
import {
  createXLSX,
  saveAsExcelFile,
  getFormattedDateInterval,
} from "../../helpers/xlsx";
export default function DataTableIstoricPlati() {
  const [istoricPlatiTableData, setIstoricPlatiTableData] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const dt = useRef(null);

  const getPlatiCallback = async () => {
    setIsDataFetching(true);
    const response = await requestJson("payments", RequestMethod.GET, {
      useAccessToken: true,
    });

    if (response.isSuccess) {
      setIstoricPlatiTableData(response.response.data);
      setIsDataFetching(false);
    }
  };

  const exportXLSX = (selectionOnly) => {
    const COLUMNS_EXPORT_EXCEL = {
      Transaction: "Numar transactie",
      Date: "Data",
      Total: "Total plata",
      Invoices: "Facturi achitate",
    };

    const tableHead = Object.keys(COLUMNS_EXPORT_EXCEL);
    const dataForXLSX = istoricPlatiTableData.map((item) => ({
      [COLUMNS_EXPORT_EXCEL.Transaction]: item?.transaction || "",
      [COLUMNS_EXPORT_EXCEL.Date]: item?.date || "",
      [COLUMNS_EXPORT_EXCEL.Total]: item?.total || "",
      [COLUMNS_EXPORT_EXCEL.Invoices]: item?.invoices || "",
    }));
    const datesInterval = getFormattedDateInterval(
      dataForXLSX,
      COLUMNS_EXPORT_EXCEL.Date
    );

    const title = `Istoric plati online Nova (${datesInterval})`;

    const excelBuffer = createXLSX(title, tableHead, dataForXLSX, 3);
    saveAsExcelFile(excelBuffer, title);
  };

  useEffect(() => {
    getPlatiCallback();
  }, []);

  return (
    <>
      {isDataFetching ? (
        <ComponentLoading parrentClassName={"my-account-loading-div"} />
      ) : (
        <DataTable
          ref={dt}
          className="istoricPlati-table"
          exportFilename="Istoric Plati"
          value={istoricPlatiTableData}
          header={tableIstoricPlatiHelper.header(
            istoricPlatiTableData,
            exportXLSX
          )}
          paginator
          removableSort
          rows={10}
        >
          <Column
            sortable
            field="transaction"
            header="Numar tranzactie"
            body={tableIstoricPlatiHelper.tableNumarTranzactie}
          />
          <Column
            sortable
            field="date"
            header="Data"
            body={tableIstoricPlatiHelper.tableData}
          />
          <Column
            sortable
            field="total"
            header="Total Plata"
            body={tableIstoricPlatiHelper.tableTotalPlata}
          />
          <Column
            sortable
            field="invoices"
            header="Facturi Achitate"
            body={tableIstoricPlatiHelper.tableFacturiAchitate}
          />
        </DataTable>
      )}
    </>
  );
}
