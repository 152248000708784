import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "api/auth.service";
import { styleHelpers } from "helpers/styleHelpers";

export default function ConturiAsociate( {users} ) {
  const location = useLocation();
  const navigation = useNavigate();

  const changeUser = async (user) => {
      localStorage.removeItem('account');
      localStorage.removeItem('locuriDeConsum');

    const  response = await AuthService.switchUser(user);

    if (response.isSuccess) {
        window.location.reload();
    }
  }

  return (
    <div className="header-edit-profile">
      <div className="header-conturi-asociate">
        Conturi asociate
      </div>

      <div className="header-conturi-asociate-content">
        {users.map((user, index) => (
          <div
            key={index}
            onClick={() => navigation("/contul-meu")}
            className={`${styleHelpers.getEPDivClass(location, "/contul-meu")}`}
          >
            <p onClick={() =>  changeUser(user)}>{user.first_name + " " + user.last_name} </p>
          </div>
        ))}
      </div>
    </div>
  );
}
