import React, {useEffect, useState} from "react";
import ModalDeschideTichet from "components/Suport/ModalDeschideTichet";
import SubmitButton from "components/SubmitButton";
import CHAT from "assets/images/support/chat.svg";
import CALLER from "assets/images/support/caller.svg";
import PHONE from "assets/images/support/phone.svg";
import {apiLocuriDeConsum} from "api/locuriDeConsum.service";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";

export default function SuportCardRow({
  setFetchAgainSolicitari,
}) {
  const [showDeschideTichet, setShowDeschideTichet] = useState(false);
  const [optionsLocDeConsum, setOptionsLocDeConsum] = useState([]);

  const getLocDeConsumOptions = async () => {
    const response = await apiLocuriDeConsum.getLocuriDeConsum();
    if (response.isSuccess) {

      setOptionsLocDeConsum( response.response.data.map((item) => ({
        value: item.metering_point_id,
        label: <div><img src={item.utility_type === "Gaze" ? GAZ : CURENT} height={'30px'}/>{ ' ' + item.address_summary}</div>,
        type: item.utility_type,
      })));
    }
  };

  useEffect(() => {
    getLocDeConsumOptions();
  }, []);

  if (showDeschideTichet) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "auto";

  return (
    <>
      <div className="suport-card-row">
        <div className="suport-card">
          <img className="suport-card-img" src={CHAT} alt="chat" />
          <div className="suport-card-scris">
            <h2>Suport clienti</h2>
            <a href="tel:0219246" className="p-">021 92 46</a>
            <SubmitButton
              text="Deschide tichet"
              classNames={"buton-bg-alb-green"}
              onClick={() => setShowDeschideTichet(true)}
            />
          </div>
        </div>
        <div className="suport-card">
          <img className="suport-card-img" src={CALLER} alt="caller" />
          <div className="suport-card-scris">
            <h2>Nova Power & Gas Hotline</h2>
            <p>
              Distributie gaze naturaleCampulung Moldovenesc, Moara si Huedin.
            </p>
            <SubmitButton
              icon={PHONE}
              text="0800 801 010"
              telephoneLink="0800801010"
              classNames={"buton-bg-alb-green buton-call-suport"}
            />
          </div>
        </div>
      </div>
      {showDeschideTichet && (
        <ModalDeschideTichet
          setFetchAgainSolicitari={setFetchAgainSolicitari}
          setShowDeschideTichet={setShowDeschideTichet}
          optionsLocDeConsum={optionsLocDeConsum}
        />
      )}
    </>
  );
}
