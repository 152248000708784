import React, { useState, useEffect } from "react";
import { apiLocuriDeConsum } from "api/locuriDeConsum.service";
import { useNavigate } from "react-router-dom";
import LocuriDeConsumCard from "components/Dashboard/LocuriDeConsumCard";
import ComponentLoading from "components/Loaders/ComponentLoading";
import SubmitButton from "components/SubmitButton";

export default function LocuriDeConsum() {
  const navigation = useNavigate();
  const [locuriDeConsum, setLocuriDeConsum] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);

  useEffect(() => {
    const getLocuriDeConsum = async () => {
      setIsDataFetching(true);
      const response = await apiLocuriDeConsum.getLocuriDeConsum();
      if (response.isSuccess) {
        setLocuriDeConsum(response.response.data);
        setIsDataFetching(false);
      }
    };

    getLocuriDeConsum();
  }, []);

  return (
    <div className="dashboard-locuri-consum">
      <h2>Locuri de consum</h2>

      {isDataFetching ? (
        <ComponentLoading parrentClassName={"my-account-loading-div"} />
      ) : (
        <>
          {locuriDeConsum.map((loc, index) => {
            const isLastItem = index === locuriDeConsum.length - 1;
            return (
              <LocuriDeConsumCard
                key={loc.metering_point_id}
                item={loc}
                isLastItem={isLastItem}
              />
            );
          })}
          <SubmitButton
            text="Toate locurile de consum"
            classNames={"toate-loc-consum-btn"}
            onClick={() => navigation("/locuri-de-consum")}
          />
        </>
      )}
    </div>
  );
}
