import React from "react";

import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";

export default function ContracteleMeleCard({ item, isLastItem }) {
  return (
    <div className={`contractele-mele-card ${isLastItem && "mr-0"}`}>
      <div
        className={`gaz-curent-icon-contracte ${
          item.utility_type === "energy" &&
          "gaz-curent-icon-electricitate-contracte"
        }`}
      >
        <img
          src={item.utility_type === "energy" ? CURENT : GAZ}
          alt="gaz_curent_icon"
        />
      </div>
      <div className="contractele-mele-content-card">
        <div className="contractele-mele-card-title">
          <h2>Contract {item.contract_number}</h2>
          <div className="contractele-mele-card-incetat">
            <p>{item.contract_status}</p>
          </div>
          {/* {item.incetat && (
            <div className="contractele-mele-card-incetat">
              <p>INCETAT</p>
            </div>
          )} */}
        </div>
        {/* <p>{item.utilizatorType}</p> */}
        {/* <p>{item.adresa}</p> */}
        <p>{item.contract_type}</p>
        <p>
          {item.address}
        </p>
        <div className="contactele-mele-container-second">
          <div className="two-p-div first-2-p-div">
            <p>Semnat</p>
            <p>{item.sign_date}</p>
          </div>
          <div className="two-p-div">
            <p>Intrat in vigoare</p>
            <p>{item.start_date}</p>
          </div>
        </div>
        <div className="contactele-mele-container-third">
          <div className="two-p-div first-2-p-div">
            <p>Tip</p>
            <p>{item.invoice_sending_type}</p>
            {/* <p>{item.tip}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
