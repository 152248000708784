const firstTwoLetters = (str) => {
  let words = str.split(" ");
  let result = "";
  for (let i = 0; i < Math.min(2, words.length); i++) {
    if (words[i].length > 0) {
      result += words[i][0].toUpperCase();
    }
  }
  return result;
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

const areObjectsEqual = (obj1, obj2, keyMapping) => {
  const nonMatching = {};

  for (const key in keyMapping) {
    if (obj1[key] !== obj2[keyMapping[key]]) {
      nonMatching[key] = obj2[keyMapping[key]];
    }
  }

  return {
    isEqual: Object.keys(nonMatching).length > 0 ? false : true,
    nonMatching: Object.keys(nonMatching).length > 0 ? nonMatching : null,
  };
};

const isAvailableSelfReading = () => {

  // return  day >= 19 && day <= 26;
  return true;
};

export const helpers = {
  firstTwoLetters,
  scrollToTop,
  areObjectsEqual,
  isAvailableSelfReading,
};
export const calculatePaginationArray = (data, itemsPerPage = 5) => {
  if (!data || data.length === 0) {
    return [];
  }

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const array = [];
  for (let i = 1; i <= totalPages; i++) {
    array.push(itemsPerPage * i);
  }

  return array;
};

export const getSuccessUrlPayment = (invoice_id) => {
  if (!invoice_id) {
    console.warn('NO INVOICE ID FOR SUCCESS URL PAYMENT ID')
    return
  }
  return `${window.location.href}?paid_invoice_id=${encodeURIComponent(invoice_id)}&payment_success=true`;


}