import { requestJson, RequestMethod } from "helpers/httpHelper";

const getSuportTableData = async () => {
  const response = await requestJson("incidents", RequestMethod.GET, {
    useAccessToken: true
  });
  return response;
};

const postTicket = async (data) => {
  const response = await requestJson("incidents", RequestMethod.POST, {
    useAccessToken: true,
    headers: [
      { name: "Content-Type", value: "application/x-www-form-urlencoded" },
    ],
    data: data,
  });
  return response;
};

export const apiSuport = {
  getSuportTableData,
  postTicket,
};
