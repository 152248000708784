import { utils as xlsxUtils, write } from "xlsx";
import fileSaver from "file-saver";
/**
 * Function to create an XLSX file with a given title, table headers, and data.
 *
 * @param {string} title - The title of the XLSX file
 * @param {Array} tableHead - The table headers
 * @param {Array} data - The data to be included in the XLSX file
 * @param {number} colIndexExtraSpace - The extra space for column index
 * @return {ArrayBuffer} The created XLSX file as an ArrayBuffer
 */
export const createXLSX = (
  title = "",
  tableHead = [],
  data = [],
  colIndexExtraSpace = 2
) => {
  // Create a workbook
  const wb = xlsxUtils.book_new();
  const ws = xlsxUtils.aoa_to_sheet([]);

  ws["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: tableHead.length - 1 } }]; // Merge cells A1 to C1

  ws["A1"] = {
    t: "s",
    v: title,
  };
  xlsxUtils.sheet_add_aoa(ws, [tableHead], { origin: "A3" });
  /* create !cols array if it does not exist */

  if (!ws["!cols"]) ws["!cols"] = [];
  // set width for "loc consum" column
  if (!ws["!cols"][colIndexExtraSpace]) {
    ws["!cols"][colIndexExtraSpace] = { wch: 8 };
  }
  ws["!cols"][colIndexExtraSpace].wpx = colIndexExtraSpace === 2 ? 200 : 300;
  // set width for "scadenta la" column
  if (!ws["!cols"][colIndexExtraSpace + 1]) {
    ws["!cols"][colIndexExtraSpace + 1] = { wch: 4 };
  }
  ws["!cols"][colIndexExtraSpace + 1].wpx = 65;

  // Add an empty row after the title and header
  ws[xlsxUtils.encode_cell({ r: 1, c: 0 })] = { t: "s", v: "" }; // Assuming the empty row is added after row 2

  // Add the data rows
  xlsxUtils.sheet_add_json(ws, data, {
    skipHeader: true,
    origin: "A4",
  });

  // Add the worksheet to the workbook
  xlsxUtils.book_append_sheet(wb, ws, "Sheet1"); // 'Sheet1' is the name of the sheet

  const excelBuffer = write(wb, {
    bookType: "xlsx",
    type: "array",
    cellStyles: true,
  });

  return excelBuffer;
};
/**
 * Saves the given buffer as an Excel file with the specified file name.
 *
 * @param {ArrayBuffer} buffer - The buffer containing the Excel data
 * @param {string} fileName - The name of the Excel file
 * @return {void}
 */
export const saveAsExcelFile = (buffer, fileName) => {
  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const data = new Blob([buffer], {
    type: EXCEL_TYPE,
  });
  try {
    fileSaver.saveAs(data, `${fileName}.xlsx`);
  } catch (error) {}
};

function parseMonthName(monthName) {
  const monthNames = [
    "ianuarie",
    "februarie",
    "martie",
    "aprilie",
    "mai",
    "iunie",
    "iulie",
    "august",
    "septembrie",
    "octombrie",
    "noiembrie",
    "decembrie",
  ];

  return monthNames.indexOf(monthName.toLowerCase());
}

function parseDateString(dateString) {
  const [monthName, yearString] = dateString.split(" ");

  if (!monthName || !yearString) {
    return null; // Invalid format
  }

  const month = parseMonthName(monthName);
  const year = parseInt(yearString, 10);

  if (isNaN(month) || isNaN(year)) {
    return null; // Invalid month name or year
  }

  return new Date(year, month);
}

export function getFormattedDateInterval(
  data,
  dateAttributeName,
  isOnyYearAndMonth = false
) {
  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }

  // Extract the first and last dates in the original format

  let firstDate = new Date(data[0][dateAttributeName]);
  let lastDate = new Date(data[data.length - 1][dateAttributeName]);
  if (isOnyYearAndMonth) {
    firstDate = parseDateString(data[0][dateAttributeName]);
    lastDate = parseDateString(data[data.length - 1][dateAttributeName]);
  }

  // Format the dates
  const options = { month: "long", year: "numeric" };
  const formattedFirstDate = firstDate.toLocaleDateString("ro-RO", options);
  const formattedLastDate = lastDate.toLocaleDateString("ro-RO", options);

  // Combine the formatted dates
  const formattedInterval = `${formattedFirstDate} - ${formattedLastDate}`;

  return formattedInterval;
}
