import SubmitButton from "components/SubmitButton";

const tableNumarTranzactie = (rowData) => {
  return (
    <div className="istoricPlati-table-numarTranzactie">
      <p>{rowData.transaction}</p>
    </div>
  );
};

const tableData = (rowData) => {
  return (
    <div className="istoricPlati-table-data">
      <p>{rowData.date}</p>
    </div>
  );
};

const tableTotalPlata = (rowData) => {
  return (
    <div className="istoricPlati-table-totalPlata">
      <p>{rowData.total}</p>
    </div>
  );
};

const tableFacturiAchitate = (rowData) => {
  return (
    <div className="istoricPlati-table-facturiAchitate">
      <p>{rowData.invoices}</p>
    </div>
  );
};

const header = (istoricPlatiTableData, exportXLSX) => {
  return (
    <div className="istoricPlati_table_header">
      <p>{istoricPlatiTableData.length} intregistrari</p>
      <SubmitButton
        text="Export"
        onClick={exportXLSX}
        data-pr-tooltip="CSV"
        classNames={"buton-bg-alb-green"}
      />
    </div>
  );
};

export const tableIstoricPlatiHelper = {
  header,
  tableNumarTranzactie,
  tableData,
  tableTotalPlata,
  tableFacturiAchitate,
};
