import React from "react";
import { ReactComponent as BACK_BUTTON } from "assets/images/common/back_button.svg";

export default function LocDeConsumContainer({
  locDeConsumShowing,
  setLocDeConsumShowing,
}) {
  return (
    <div className="locDeConsumContainer-header">
      <BACK_BUTTON
        onClick={() =>
          setLocDeConsumShowing({
            id: null,
            isShowing: false,
            locDeConsum: null,
          })
        }
      />
      <div className="locDeConsumHeader-header">
        <p>Loc de consum</p>
        <h2>{locDeConsumShowing.locDeConsum}</h2>
      </div>
    </div>
  );
}
