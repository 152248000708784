import React from "react";

export default function ScreenLoading() {
  return (
    <div className="screenLoader-container">
      <div className="loader loader-1">
        <div className="loader-outter" />
        <div className="loader-inner" />
      </div>
    </div>
  );
}
