import React, {useState, useEffect, useRef} from "react";
import { apiContulMeu } from "api/contulMeu.service";
import ModalModificaDate from "components/ContulMeu/Modal/ModalModificaDate";
import ModalSchimbaParola from "components/ContulMeu/Modal/ModalSchimbaParola";
import SubmitButton from "components/SubmitButton";
import ComponentLoading from "components/Loaders/ComponentLoading";
import {useNavigate} from "react-router-dom";
import {Toast} from "primereact/toast";
import {ConfirmDialog} from "primereact/confirmdialog";
import {toast} from "react-toastify";

export default function ContulMeuDetails() {
  const [userDetails, setUserDetails] = useState({
    data: [],
    fetchAgain: 0,
  });
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [showModificaDate, setShowModificaDate] = useState(false);
  const [showModficaParola, setShowModficaParola] = useState(false);
  const [visible, setVisible] = useState(false);
    const toastRef = useRef(null);

  const navigation = useNavigate();

  const deleteContact = async (user) => {
    setIsDataFetching(true);
    const response = await apiContulMeu.deleteContact({
      delete_account: true
    });
    setIsDataFetching(false);
    if (response.isSuccess) {
      localStorage.clear();
      navigation("/");
      toast.success("Utilizatorul a fost sters cu succes");
    }else{
      toast.error("A aparut o eroare la stergerea utilizatorului");
    }
  };

  const accept = (user) => {
    deleteContact(user);
  }

  const reject = (user) => {
    setVisible(false);
  }


  useEffect(() => {
    const getUserDetails = async () => {
      setIsDataFetching(true);
      const response = await apiContulMeu.getAllAccountDetails();
      if (response.isSuccess) {
        setUserDetails((prevState) => ({
          ...prevState,
          data: response.response.data,
        }));
        setIsDataFetching(false);
      }
    };
    getUserDetails();
  }, [userDetails.fetchAgain]);

  if (showModificaDate || showModficaParola)
    document.body.style.overflow = "hidden";
  else document.body.style.overflow = "auto";

  return (
    <div className="my-account-div">
      <h2>Contul meu</h2>
      {isDataFetching ? (
        <ComponentLoading parrentClassName={"my-account-loading-div"} />
      ) : (
        <>
          <div className="my-account-div-content">
            <div className="my-account-details">
              <p>Adresa</p>
              <p>{userDetails.data.address}</p>
            </div>

            <div className="my-account-details">
              <p>Email</p>
              <p>{userDetails.data.email}</p>
            </div>

            <div className="my-account-details">
              <p>Telefon</p>
              <p>{userDetails.data.phone_number}</p>
            </div>
          </div>
          <div className="my-account-line" />
          <div className="my-account-buttons">
            <SubmitButton
              onClick={() => navigation("/suport")}
              classNames={"buton-bg-alb-green"}
              text={"Modifica date"}
            />
            <SubmitButton
              onClick={() => setShowModficaParola(true)}
              classNames={"buton-bg-alb-green"}
              text={"Schimba parola"}
            />
            {visible && (
                <>
                            <Toast ref={toastRef} />
                            <ConfirmDialog
                                visible={visible}
                                onHide={() => setVisible(false)}
                                message={`Vrei sa stergi utilizatorul cu adresa de email: ${userDetails.data.email}?`}
                                header="Confirma stergerea"
                                icon= 'pi pi-info-circle'
                                acceptLabel="Da"
                                rejectLabel="Inchide"
                                acceptClassName="p-button-danger"
                                accept={() => accept(userDetails.data)}
                                reject={() => reject(userDetails.data)}
                            />
                        </>
            )}
               <SubmitButton
                   onClick={() => setVisible(true)}
                   classNames={"buton-bg-alb-red ml-4"}
                   text={"Sterge cont"}
               />
          </div>

          {showModificaDate && (
            <ModalModificaDate
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              setShowModificaDate={setShowModificaDate}
            />
          )}

          {showModficaParola && (
            <ModalSchimbaParola setShowModficaParola={setShowModficaParola} />
          )}
        </>
      )}
    </div>
  );
}
