import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ro from "date-fns/locale/ro";

export default function DateInput({
   wrapperClassName,
   pickerClassName,
   placeholderText,
   id,
   setFieldValue,
   objectRoute,
   value,
   error,
   touched,
    maxDate
}) {
   return (
      <>
         <ReactDatePicker
            wrapperClassName={`${wrapperClassName} ${error && touched && "error-datepicker-wrapper"}`}
            className={pickerClassName}
            name={id}
            id={id}
            selected={value ? new Date(value) : ""}
            placeholderText={placeholderText}
            onChange={date => setFieldValue(`${objectRoute}`, date)}
            dateFormat="yyyy.MM.dd"
            locale={ro}
            maxDate={maxDate}
         />
         {error && touched && <p className="error-msg">{error}</p>}
      </>
   );
}
