import React from "react";
import INVOICE from "assets/images/dashboard/invoice.png";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import ComponentLoading from "components/Loaders/ComponentLoading";
import { getSuccessUrlPayment } from "helpers/helpers";
export default function SoldCurent({ soldCurent = null }) {



  const handlePlatesteToate = async (event, data) => {
    event.preventDefault();
    await requestJson(`invoices/in-progress`, RequestMethod.POST, {

      useAccessToken: true,
      data: {
        invoice_id: 'all'
      }
    });

    const form = event.target.closest("form");
    form.submit();
  };

  const successUrl = getSuccessUrlPayment('all');

  return (
    <div className="dashboard-sold-container">
      <div className="sold-container-details">
        <div className="sold-container-elements">
          <div id="dashboard-red-card-sold-wrapper">
            <div>Sold curent</div>
            <div>
              {soldCurent ? (
                soldCurent?.amount !== undefined ? (
                  `${soldCurent?.amount} RON`
                ) : (
                  `0.00 RON`
                )
              ) : (
                <ComponentLoading parrentClassName={"my-account-loading-div"} />
              )}
            </div>
            <div>
              Plata soldului curent care poate contine mai multe facturi
            </div>

          </div>
          <div id="dashboard-red-card-wrapper" >
            <img src={INVOICE} id="dashboard-red-card-image-wrapper" alt="invoice_icon" />
          </div>

        </div>

        {soldCurent !== null && soldCurent?.amount > 0 && (
          <form
            action="https://secure.euplatesc.ro/tdsprocess/tranzactd.php"
            method="POST"
            name="gateway"
            target="_self"
          >
            <input type="hidden" name="amount" value={soldCurent?.amount} />
            <input type="hidden" name="curr" value={soldCurent?.curr} />
            <input
              type="hidden"
              name="invoice_id"
              value={soldCurent.invoice_id}
            />
            <input
              type="hidden"
              name="order_desc"
              value={soldCurent.order_desc}
            />
            <input type="hidden" name="merch_id" value={soldCurent.merch_id} />
            <input
              type="hidden"
              name="timestamp"
              value={soldCurent.timestamp}
            />
            <input type="hidden" name="nonce" value={soldCurent.nonce} />
            <input type="hidden" name="c2p_id" value={soldCurent.c2p_id} />
            <input type="hidden" name="fp_hash" value={soldCurent.fp_hash} />
            <input type="hidden" name="email" value={soldCurent.email} />
            <input type="hidden" name="fname" value={soldCurent.fname} />
            <input
              type="hidden"
              name="ExtraData[successurl]"
              value={successUrl}
            />
            <input
              type="hidden"
              name="ExtraData[backtosite]"
              value={soldCurent["ExtraData[backtosite]"]}
            />
            <input
              type="hidden"
              name="ExtraData[invoices_check]"
              value={soldCurent["ExtraData[invoices_check]"]}
            />
            <input
              type="hidden"
              name="ExtraData[invoices]"
              value={soldCurent["ExtraData[invoices]"]}
            />

            <button
              type="submit"
              title={!soldCurent?.userHasSuccefullyPaidTotalInvoicesRecently ? "Plateste online" : "Butonul este dezactivat pentru procesarea platii pe care tocmei ai facut-o. Va dura maxim 15 minute."}
              disabled={soldCurent?.userHasSuccefullyPaidTotalInvoicesRecently}
              onClick={(event) => handlePlatesteToate(event, soldCurent)}
              className="custom-submitButton dashboard-plateste"
            >
              {soldCurent?.userHasSuccefullyPaidTotalInvoicesRecently ? 'Se proceseaza plata..' : 'Plateste'}
            </button>
          </form>
        )}
        {soldCurent?.unallocated_amount > 0 && (
          <div className={"mt-1"}>
            <h6>Sold nerepartizat</h6>
            <h6>{soldCurent?.unallocated_amount} RON</h6>
          </div>
        )}
      </div>
    </div>
  );
}
