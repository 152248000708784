import React from "react";
import LeftMenu from "components/LeftMenu";
import Header from "components/Header/Header";
import Footer from "components/Footer";

export default function Layout({ children }) {
  return (
    <div className="page-layout">
      <LeftMenu />
      <div className="page-layout-content-right">
        <Header />
        <div className="container-page-content">{children}</div>
      </div>
      <Footer />
    </div>
  );
}
