import * as echarts from "echarts";

const optionsGraficDashboard = {
  color: ["#52C2B5"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: ["Dec", "Ian", "Feb", "Mar", "Apr", "Mai"],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "Facturi",
      data: [800, 400, 350, 300, 200, 150, 100],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#52C2B5" },
          { offset: 1, color: "rgba(82, 194, 181, 0)" },
        ]),
      },
    },
  ],
};

const optionsRSEnergieElectrica = {
  color: ["#52C2B5", "#4669E7"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Dec",
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "2022",
      data: [2.0, 1.8, 1, 0.5, 0.7, 1.3, 1.4, 1.1, 1.7, 1.65, 1.5, 1.1],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#52C2B5" },
          { offset: 1, color: "rgba(82, 194, 181, 0)" },
        ]),
      },
    },
    {
      name: "2023",
      data: [1.5, 1.4, 0.7, 0.3, 0.5, 0.9, 1.1, 0.8, 1.3, 1.75, 2, 1.8],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#4669E7" },
          { offset: 1, color: "rgba(164, 91, 157, 0)" },
        ]),
      },
    },
  ],
};

const optionsRSGaze = {
  color: ["#F29857", "#8E41A9"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Dec",
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "2022",
      data: [2.0, 1.8, 1, 0.5, 0.7, 1.3, 1.4, 1.1, 1.7, 1.65, 1.5, 1.1],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#F29857" },
          { offset: 1, color: "rgba(242, 152, 87, 0)" },
        ]),
      },
    },
    {
      name: "2023",
      data: [1.5, 1.4, 0.7, 0.3, 0.5, 0.9, 1.1, 0.8, 1.3, 1.75, 2, 1.8],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#8E41A9" },
          { offset: 1, color: "rgba(142, 65, 169, 0)" },
        ]),
      },
    },
  ],
};

const optionsRDPrezentare = {
  color: ["#52C2B5", "#4669E7"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Dec",
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "2022",
      data: [205, 200, 167, 187, 150, 163, 150, 70, 80, 50, 30, 0],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#52C2B5" },
          { offset: 1, color: "rgba(82, 194, 181, 0)" },
        ]),
      },
    },
    {
      name: "2023",
      data: [130, 120, 110, 147, 140, 163, 90, 100, 80, 90, 105, 130],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#4669E7" },
          { offset: 1, color: "rgba(164, 91, 157, 0)" },
        ]),
      },
    },
  ],
};

const optionsRDEnergieElectricaUNU = {
  color: ["#107B9D", "#52C2B5"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Dec",
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "2022",
      data: [2.0, 1.8, 1, 0.5, 0.7, 1.3, 1.4, 1.1, 1.7, 1.65, 1.5, 1.1],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#107B9D" },
          { offset: 1, color: "rgba(16, 123, 157, 0)" },
        ]),
      },
    },
    {
      name: "2023",
      data: [1.5, 1.4, 0.7, 0.3, 0.5, 0.9, 1.1, 0.8, 1.3, 1.75, 2, 1.8],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#52C2B5" },
          { offset: 1, color: "rgba(82, 194, 181, 0)" },
        ]),
      },
    },
  ],
};

const optionsRDEnergieElectricaDOI = {
  color: ["#5856B1", "#4669E7"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Dec",
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "2022",
      data: [2.0, 1.8, 1, 0.5, 0.7, 1.3, 1.4, 1.1, 1.7, 1.65, 1.5, 1.1],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#5856B1" },
          { offset: 1, color: "rgba(88, 86, 177, 0)" },
        ]),
      },
    },
    {
      name: "2023",
      data: [1.5, 1.4, 0.7, 0.3, 0.5, 0.9, 1.1, 0.8, 1.3, 1.75, 2, 1.8],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#4669E7" },
          { offset: 1, color: "rgba(70, 105, 231, 0)" },
        ]),
      },
    },
  ],
};

const optionsRDGazeNaturaleUNU = {
  color: ["#C057A3", "#8E41A9"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Dec",
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "2022",
      data: [2.0, 1.8, 1, 0.5, 0.7, 1.3, 1.4, 1.1, 1.7, 1.65, 1.5, 1.1],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#C057A3" },
          { offset: 1, color: "rgba(192, 87, 163, 0)" },
        ]),
      },
    },
    {
      name: "2023",
      data: [1.5, 1.4, 0.7, 0.3, 0.5, 0.9, 1.1, 0.8, 1.3, 1.75, 2, 1.8],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#8E41A9" },
          { offset: 1, color: "rgba(142, 65, 169, 0)" },
        ]),
      },
    },
  ],
};

const optionsRDGazeNaturaleDOI = {
  color: ["#F29857", "#D34102"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Dec",
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "2022",
      data: [2.0, 1.8, 1, 0.5, 0.7, 1.3, 1.4, 1.1, 1.7, 1.65, 1.5, 1.1],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#F29857" },
          { offset: 1, color: "rgba(242, 152, 87, 0)" },
        ]),
      },
    },
    {
      name: "2023",
      data: [1.5, 1.4, 0.7, 0.3, 0.5, 0.9, 1.1, 0.8, 1.3, 1.75, 2, 1.8],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#D34102" },
          { offset: 1, color: "rgba(211, 65, 2, 0)" },
        ]),
      },
    },
  ],
};

const optionsRDCurbaConsum = {
  color: ["#52C2B5", "#4669E7"],
  grid: { show: false },
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Dec",
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "2022",
      data: [205, 200, 167, 187, 150, 163, 150, 70, 80, 50, 30, 0],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#52C2B5" },
          { offset: 1, color: "rgba(82, 194, 181, 0)" },
        ]),
      },
    },
    {
      name: "2023",
      data: [130, 120, 110, 147, 140, 163, 90, 100, 80, 90, 105, 130],
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#4669E7" },
          { offset: 1, color: "rgba(164, 91, 157, 0)" },
        ]),
      },
    },
  ],
};

const graphData = {
  optionsGraficDashboard,
  optionsRSEnergieElectrica,
  optionsRSGaze,
  optionsRDPrezentare,
  optionsRDEnergieElectricaUNU,
  optionsRDEnergieElectricaDOI,
  optionsRDGazeNaturaleUNU,
  optionsRDGazeNaturaleDOI,
  optionsRDCurbaConsum,
};

export default graphData;
