import React, { useState } from "react";
import Layout from "pages/Layout";
import SuportCardRow from "components/Suport/SuportCardRow";
import DataTableSuport from "components/Suport/DataTableSuport";

export default function Suport() {
  const [fetchAgainSolicitari, setFetchAgainSolicitari] = useState(0);

  return (
    <Layout>
      <SuportCardRow
        setFetchAgainSolicitari={setFetchAgainSolicitari}
      />
      <DataTableSuport
        fetchAgainSolicitari={fetchAgainSolicitari}
      />
    </Layout>
  );
}
