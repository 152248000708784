import React from "react";

export default function SubmitButton({
  icon,
  text,
  classNames,
  onClick,
  typeOfButton,
  telephoneLink,
  disabled = false,
}) {
  // Dacă este furnizat un link de telefon, returnăm un element <a>
  if (telephoneLink) {
    return (
      <a
        href={`tel:${telephoneLink}`}
        className={`custom-submitButton ${classNames}`}
      >
        {icon && <img src={icon} alt="icon" />}
        {text}
      </a>
    );
  }

  // Altfel, returnăm un element <button>
  return (
    <button
      disabled={disabled}
      onClick={onClick === false ? null : onClick}
      type={typeOfButton ? typeOfButton : "button"}
      className={`custom-submitButton ${classNames}`}
    >
      {icon && <img src={icon} alt="icon" />}
      {text}
    </button>
  );
}
