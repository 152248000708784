import React from "react";
import Select from "react-select";
import { styleHelpers } from "helpers/styleHelpers";

export default function SelectInput({
  onChange,
  onMenuOpen,
  selectedKeyFromOptions = "value",
  options,
  value,
  placeholder,
  error,
  touched,
  id,
  name,
  labelDescription,
  parrentClassName,
  reactSelectClassName,
  isLabel = true,
}) {
  const defaultValue = (options, value) => {
    return options
      ? options.find((option) => option[selectedKeyFromOptions] === value)
      : "";
  };

  return isLabel ? (
    <div className={`${parrentClassName}`}>
      <label htmlFor={name}>{labelDescription}</label>
      <Select
        id={id}
        name={name}
        classNamePrefix={reactSelectClassName}
        value={value ? defaultValue(options, value) : ""}
        onChange={(value) => onChange(value)}
        onMenuOpen={onMenuOpen}
        options={options}
        placeholder={placeholder}
        styles={
          error ? styleHelpers.errorSelectStyle : styleHelpers.normalStyle
        }
      />
      {error && touched && <p className="error-msg">{error}</p>}
    </div>
  ) : (
    <>
      <Select
        id={id}
        name={name}
        classNamePrefix={reactSelectClassName}
        value={value ? defaultValue(options, value) : ""}
        onChange={(value) => onChange(value)}
        onMenuOpen={onMenuOpen}
        options={options}
        placeholder={placeholder}
        styles={
          error ? styleHelpers.errorSelectStyle : styleHelpers.normalStyle
        }
      />
      {error && touched && <p className="error-msg">{error}</p>}
    </>
  );
}
