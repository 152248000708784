import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";
import SubmitButton from "components/SubmitButton";

const tableTipFurnizare = (rowData) => {
  return (
    <div className="transmitereFactura-table-tipFurnizare">
      <img
        src={rowData.utility_type === "gas" ? GAZ : CURENT}
        alt="tip furnizare"
      />
    </div>
  );
};

const tableDetaliiContract = (rowData) => {
  return (
    <div className="transmitereFactura-table-detaliiContract">
      <p>{rowData.contract_number}</p>
    </div>
  );
};

const tableTransmitereFactura = (rowData) => {
  return (
    <div className="transmitereFactura-table-transmitereFactura">
      <div className="transmitereFactura-table-buttons">
        <div className={`transmitereFactura-table-button `}>
          <p>{rowData.invoice_sending_type}</p>
        </div>
      </div>
    </div>
  );
};

const header = (contracteNumber, exportXLSX) => {
  return (
    <div className="transmitereFactura_table_header">
      <p>{contracteNumber} contracte</p>
      <SubmitButton
        text="Export"
        onClick={exportXLSX}
        data-pr-tooltip="CSV"
        classNames={"buton-bg-alb-green"}
      />
    </div>
  );
};

export const tableTransmitereFacturaHelper = {
  header,
  tableTipFurnizare,
  tableDetaliiContract,
  tableTransmitereFactura,
};
