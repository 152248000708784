import React, {useRef, useState} from "react";
import { helpers } from "helpers/helpers";
import SubmitButton from "components/SubmitButton";
import {useNavigate} from "react-router-dom";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import {apiContulMeu} from "api/contulMeu.service";
import {toast} from "react-toastify";

export default function UtilizatoriCard({
  item,
  isLastItem,
}) {
    const [visible, setVisible] = useState(false);
    const navigation = useNavigate();
    const toastRef = useRef(null);

    const deleteContact = async (user) => {
        const response = await apiContulMeu.deleteContact({
            contact_id: user.contact_id,
        });

        if (response.isSuccess) {
            window.location.reload(true)
            // navigation("/contul-meu");
            toast.success("Utilizatorul a fost sters cu succes");
        }else{
            toast.error("A aparut o eroare la stergerea utilizatorului");
        }
    };

    const accept = (user) => {
        deleteContact(user);
    }

    const reject = (user) => {
        setVisible(false);
    }

    return (
             <div className={`utilizatori-card ${isLastItem && "mb-0"}`}>
                  <div className="d-flex">
                    <div className="utilizatori-bg">
                      <p>
                        {helpers.firstTwoLetters(
                          `${item.first_name + " " + item.last_name}`
                        )}
                      </p>
                    </div>
                    <div className="utilizatori-details">
                      <h2>{item.first_name + " " + item.last_name}</h2>
                      <p>{item.email}</p>
                    </div>
                  </div>

                  <div className="utilizatori-btns">
                    <SubmitButton
                      onClick={() =>
                          navigation("/suport" )
                      }
                      classNames={"buton-bg-alb-green utilizatori-btn-modifica"}
                      text={"Modifica"}
                    />
                      {visible && (
                          <>
                            <Toast ref={toastRef} />
                            <ConfirmDialog
                                visible={visible}
                                onHide={() => setVisible(false)}
                                message={`Vrei sa stergi utilizatorul cu adresa de email: ${item.email}?`}
                                header="Confirma stergerea"
                                icon= 'pi pi-info-circle'
                                acceptLabel="Da"
                                rejectLabel="Inchide"
                                acceptClassName="p-button-danger"
                                accept={() => accept(item)}
                                reject={() => reject(item)}
                            />
                        </>
                      )}

                      <SubmitButton
                          onClick={() => setVisible(true)}
                          classNames={"buton-bg-alb-red utilizatori-btn-dezactiveaza"}
                          text={"Sterge"}
                      />
                  </div>
             </div>
  );
}
