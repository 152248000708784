import React from "react";

import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";

export default function LocuriDeConsumCard({ item, isLastItem }) {
  const { address, address_summary, contract_type, utility_type } = item;

  return (
    <div
      style={{ marginBottom: "24px" }}
      className={`locuri-consum-content ${isLastItem && "mb-0"}`}
    >
      <div className="locuri-consum-icons">
        <div
          className={`gaz-curent-icon ${
            utility_type === "Electricitate" && "curent-background"
          }`}
        >
          <img
            src={utility_type === "Electricitate" ? CURENT : GAZ}
            alt="gaz_curent_icon"
          />
        </div>
      </div>
      <p>{address_summary}</p>
      <p>{contract_type}</p>
      <p>{address}</p>
    </div>
  );
}
