import React, { useState } from "react";

export default function TextInput({
                                     id,
                                     name,
                                     type,
                                     placeholder,
                                     classNames,
                                     value,
                                     error,
                                     touched,
                                     setFieldValue,
                                     isLoginInput = false,
                                     allowNumbersAndDots = null,
                                     disabled = false,
                                  }) {
   const allowNumbersAndDotsFunction = e => {
      if (allowNumbersAndDots === null) return;

      const allowedCharacters = /^[0-9.]+$/;
      if (
         !allowedCharacters.test(e.key) &&
         e.key !== "Backspace" &&
         e.key !== "Delete" &&
         e.key !== "ArrowLeft" &&
         e.key !== "ArrowRight"
      ) {
         e.preventDefault();
      }
   };

   const inputOnKeyDown = e => {
      allowNumbersAndDotsFunction(e);
   };
   const inputOnChange = e => {
      handleDebouncedChange(e);
   };

   //DEBOUNCE FUNCTION
   const [debouncedValue, setDebouncedValue] = useState(value);
   const [debounceTimeoutId, setDebounceTimeoutId] = useState(null);

   const handleDebouncedChange = event => {
      const { name, value } = event.target;

      if (debounceTimeoutId) {
         clearTimeout(debounceTimeoutId);
      }

      const timeoutId = setTimeout(() => {
         setFieldValue(`${name}`, value);
      }, 150);

      setDebouncedValue(value);
      setDebounceTimeoutId(timeoutId);
   };

   return (
      <>
         <input
            readOnly={isLoginInput}
            onFocus={event => isLoginInput && event.target.removeAttribute("readonly")}
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            className={`custom-textInput ${classNames} ${error && touched && "custom-textInput-error"}`}
            value={debouncedValue}
            onChange={inputOnChange}
            onKeyDown={inputOnKeyDown}
            disabled={disabled}
         />
         {error && touched && <p className="error-msg">{error}</p>}
      </>
   );
}
