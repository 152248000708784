import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import ComponentLoading from "components/Loaders/ComponentLoading";
import { getSuccessUrlPayment } from "helpers/helpers";

export default function ModalPlataPartiala({ setIsDialogVisible, isDialogVisible, selectedInvoice }) {
    const [paymentAmount, setPaymentAmount] = useState(selectedInvoice.amount);
    const [dataToSent, setDataToSent] = useState(null);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const formRef = useRef();

    // Handler pentru schimbarea valorii de plată
    const handlePaymentChange = (event) => {
        const value = event.target.value;
        // valoarea nu depășește suma totală a facturii
        if (value === '' || (Number(value) && Number(value) <= selectedInvoice.amount)) {
            setPaymentAmount(value);
        }
    };

    // Handler pentru trimiterea plății parțiale
    const submitPlataPartiala = async (event, data) => {
        event.preventDefault();
        setIsDataFetching(true);
        await requestJson(`invoices/in-progress`, RequestMethod.POST, {
            useAccessToken: true,
            data: {
                invoice_id: data.invoice_id
            }
        });

        const response = await requestJson(
            "invoices/partial",
            RequestMethod.POST,
            {
                useAccessToken: true,
                data: {
                    "invoice_number": data.invoice_id,
                    "amount": paymentAmount
                }
            },
        );

        if (response.isSuccess) {
            setDataToSent(response.response.data);
            setIsDataFetching(false);
        }
    }

    const header = () => {
        return (
            <>
                Plata partiala
            </>
        );
    };

    useEffect(() => {
        // Verificați dacă formularul există și trimiteți-l
        if (formRef.current) {
            formRef.current.submit();
        }
    }, [dataToSent]);

    const successUrl = getSuccessUrlPayment(selectedInvoice.invoice_id);

    console.log("🚀 ~ ModalPlataPartiala ~ successUrl:", successUrl);
    return (
        <Dialog header={header}
            className={"facturiMele-dialog"}
            visible={isDialogVisible} onHide={() => setIsDialogVisible(false)}
            style={{ width: '75vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>

            {isDataFetching ? (
                <ComponentLoading parrentClassName={"my-account-loading-div"} />
            ) : (
                <div className="content">
                    <div>
                        <p>
                            <span>ID Factură:</span> {selectedInvoice.invoice_id}
                        </p>
                        <div><span>Locuri de consum:</span>
                            {
                                selectedInvoice.invoice_metering_points.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <p>{index + 1}: {item.metering_point_address} - <span>Suma de plata:</span> {item.metering_point_value}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="second">
                            <p><span>Data emitere:</span> {selectedInvoice.invoice_date}</p>
                            <p><span>Data scadentă:</span> {selectedInvoice.invoice_due_date}</p>
                            <p><span>Total factura:</span> {selectedInvoice.amount}</p>
                            {/*<button type="" className="custom-submitButton dashboard_table_status_buton">Descarca</button>*/}
                        </div>


                        <div className="second">
                            <form>
                                <div className="form-group col-6">
                                    <label htmlFor="paymentAmount">Suma de plata</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="paymentAmount"
                                        placeholder="Introduceti suma de plata"
                                        value={paymentAmount}
                                        onChange={handlePaymentChange}
                                    />
                                </div>
                                <button type="submit"
                                    onClick={event => submitPlataPartiala(event, selectedInvoice)}
                                    className="custom-submitButton dashboard_table_status_buton">Plateste</button>
                            </form>

                            {dataToSent && (
                                <>
                                    <form
                                        action="https://secure.euplatesc.ro/tdsprocess/tranzactd.php"
                                        method="POST"
                                        name="formPlataPartiala"
                                        target="_self"
                                        ref={formRef}
                                    >
                                        <input type="hidden" name="amount" value={dataToSent.amount} />
                                        <input type="hidden" name="curr" value={dataToSent.curr} />
                                        <input type="hidden" name="invoice_id" value={dataToSent.invoice_id} />
                                        <input type="hidden" name="order_desc" value={dataToSent.order_desc} />
                                        <input type="hidden" name="merch_id" value={dataToSent.merch_id} />
                                        <input type="hidden" name="timestamp" value={dataToSent.timestamp} />
                                        <input type="hidden" name="nonce" value={dataToSent.nonce} />
                                        <input type="hidden" name="c2p_id" value={dataToSent.c2p_id} />
                                        <input type="hidden" name="fp_hash" value={dataToSent.fp_hash} />
                                        <input type="hidden" name="email" value={dataToSent.email} />
                                        <input type="hidden" name="fname" value={dataToSent.fname} />
                                        <input type="hidden" name="ExtraData[successurl]" value={successUrl} />
                                        <input type="hidden" name="ExtraData[backtosite]" value={dataToSent['ExtraData[backtosite]']} />
                                    </form>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            )}

        </Dialog>
    );
}
